import React, { useState } from "react";
import { CircularProgress, Modal, Snackbar } from "@mui/material";
import CloseICon from "../../assets/images/close-icon.svg";
import Next from "../../assets/images/next.svg";
import Password from "../../assets/images/passwprd.svg";
import { Formik } from "formik";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  InputLabel,
  FormControl,
  NativeSelect,
} from "@mui/material";
import { Visibility, VisibilityOff } from "../inputFields";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  resetChangePasswordMessages,
} from "../../store/reducers/auth";
import { unwrapResult } from "@reduxjs/toolkit";
import { Alert, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const modalStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  position: "absolute",
  backgroundColor: "white",
  boxShadow: 24,
  padding: 16,
  boxSizing: "border-box",
};

const ChangePasswordModal = () => {
  const [profileModal, setProfileModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    new_confirmed: false,
  });
  const { changePasswordApi } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleTogglePassword = (label) => {
    setShowPasswords({
      ...showPasswords,
      [label]: !showPasswords[label],
    });
  };

  const changePasswordHandler = (values) => {
    dispatch(changePassword(values))
      // .then(unwrapResult)
      .then((res) => {
        if (!res.error) {
          toast.success(t("password_success"));
          setProfileModal(false);
        } else {
          toast.error(res?.error?.message || t("something_went_wrong"));
        }

        // [(setProfileModal(false), setShowSnackbar(true))];
      });
  };

  return (
    <>
      <li onClick={() => setProfileModal(true)}>
        <span>
          <img src={Password} alt="" /> {t("change_password")}
        </span>
        <span>
          <img src={Next} alt="" />
        </span>
      </li>

      {/* <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Password Successfully Changed
        </Alert>
      </Snackbar> */}

      <Modal
        open={profileModal}
        onClose={() => setProfileModal(false)}
        className="main-modal"
      >
        <div style={modalStyle} className="modal-content">
          <div className="modal-body">
            <div className="modal-header location">
              <span onClick={() => setProfileModal(false)}>
                <img src={CloseICon} alt="" />
              </span>
            </div>
            <div
              className="modal-body location"
              style={{ textAlign: "initial" }}
            >
              <div className="location-marker">
                <div className="form-container">
                  {/* <div className="input-group not-account">
                    <span>Change Password</span>
                  </div> */}
                  <div className="header-back-button min-bottom">
                    {/* <span className="backbutton" onClick={() => navigation(-1)}>
                      <img src={BackButton} alt="" />
                    </span> */}
                    <Typography
                      variant="h2"
                      fontWeight="600"
                      color="#2B2B2B"
                      fontSize="28px"
                      textAlign="center"
                    >
                      {t("change_password")}
                    </Typography>
                  </div>
                  <div className="input-group not-account">
                    <span>{t("enter_new_password")}</span>
                  </div>

                  <div className="form">
                    <Formik
                      initialValues={{
                        new_password1: "",
                        new_password2: "",
                        current_password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        current_password: Yup.string()
                          .min(
                            8,
                            t("change_password_validation")
                          )
                          .matches(
                            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                            t("change_password_validation")
                          )
                          .required()
                          .label("Current Password"),
                        new_password1: Yup.string()
                          .min(
                            8,
                            t("change_password_validation")
                          )
                          .matches(
                            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                            t("change_password_validation")
                          )
                          .required()
                          .label("New Password"),
                        new_password2: Yup.string()
                          .min(
                            8,
                            t("change_password_validation")
                          )
                          .matches(
                            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                            t("change_password_validation")
                          )
                          .required()
                          .oneOf(
                            [Yup.ref("new_password1"), null],
                            t("password_match")
                          )
                          .label("Confirm Password"),
                      })}
                      onSubmit={(values) => changePasswordHandler(values)}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group">
                            <TextField
                              fullWidth
                              label={t("current_password")}
                              type={showPasswords.current ? "text" : "password"}
                              variant="standard"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="current_password"
                              value={values.current_password}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        handleTogglePassword("current")
                                      }
                                    >
                                      {showPasswords.current ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <span className="error-message">
                              {touched.current_password &&
                                errors.current_password}
                            </span>
                          </div>

                          <div className="input-group">
                            <TextField
                              fullWidth
                              label={t("new_password")}
                              type={showPasswords.new ? "text" : "password"}
                              variant="standard"
                              name="new_password1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.new_password1}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        handleTogglePassword("new")
                                      }
                                    >
                                      {showPasswords.new ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <span className="error-message">
                              {touched.new_password1 && errors.new_password1}
                            </span>
                          </div>

                          <div className="input-group change-password">
                            <TextField
                              fullWidth
                              label={t("confirm_password")}
                              type={
                                showPasswords.new_confirmed
                                  ? "text"
                                  : "password"
                              }
                              variant="standard"
                              name="new_password2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.new_password2}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        handleTogglePassword("new_confirmed")
                                      }
                                    >
                                      {showPasswords.new_confirmed ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <span className="error-message">
                              {touched.new_password2 && errors.new_password2}
                            </span>
                          </div>
                          <span className="error-message">
                            {/* {!changePasswordApi.loading &&
                              changePasswordApi.error} */}
                          </span>
                          <div className="input-group new-password-text">
                            <span>
                              {t("change_password_validation")}
                            </span>
                          </div>

                          <div className="input-group button">
                            <button
                              type="submit"
                              variant="standard"
                              color="primary"
                            >
                              {changePasswordApi.loading && (
                                <CircularProgress
                                  size={18}
                                  sx={{ color: "#ffff", marginRight: "10px" }}
                                />
                              )}
                              {t("save")}
                            </button>
                            <button
                              type="button"
                              className="cancel-button"
                              onClick={() => [
                                setProfileModal(false),
                                dispatch(resetChangePasswordMessages()),
                              ]}
                            >
                              {t("cancel")}
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
