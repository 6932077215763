import { Grid, Typography, styled } from "@mui/material";
import React from "react";
import { ReactComponent as NotificationCheck } from "../../assets/images/notificationCheck.svg";
import {
  getAllNotification,
  updateNotification,
} from "../../store/reducers/notification";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const SpecificNotification = ({ notification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const NotificationCheckIcon = styled(NotificationCheck)({
    // width: "3.625rem",
    // hight: "3.625rem",
    "*": {
      // fill: "#FFFFFF !important",
    },
  });

  const updateNotificationData = (data) => {
    dispatch(updateNotification(data)).then((res) => {
      if (!res.error) {
        dispatch(getAllNotification());
      }
    });
  };

  return (
    <Grid
      mb={1}
      p={1}
      container
      gap={1}
      justifyContent="space-between"
      sx={{
        background: notification?.is_read ? "" : "#E7E7E7",
        cursor: "pointer",
      }}
      onClick={() => {
        const data = {
          id: notification?.id,
          payload: {
            is_read: true,
          },
        };
        const currentUserType =
          notification?.data?.order_type === "drop" ? 1 : 0;
        updateNotificationData(data);
        navigate("/ViewDetails", {
          state: {
            data: notification?.data?.order_id,
            currentUserType: currentUserType,
          },
        });
      }}
    >
      <Grid item xs={10}>
        <Grid container>
          <Grid item>
            <NotificationCheckIcon />
          </Grid>
          <Grid item ml={2}>
            <Grid container flexDirection="column" justifyContent="left">
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#7B67FD",
                        fontWeight: "600",
                        fontSize: "0.75rem",
                        lineHeight: "18px",
                        textTransform: "capitalize",
                      }}
                    >
                      {notification?.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: "#2B2B2B",
                    fontWeight: "300",
                    fontSize: "0.625rem",
                    lineHeight: "12px",
                  }}
                >
                  {notification?.message}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item pt={0.5} textAlign="right" sx={{ background: "" }} xs="auto">
        <Typography
          sx={{
            color: "#989CB8",
            fontWeight: "500",
            fontSize: "0.75rem",
            lineHeight: "10px",
          }}
        >
          {dayjs(notification?.created_at)
            .format("MMM D, YYYY HH:mm")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SpecificNotification;
