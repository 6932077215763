import React, { useEffect, useState } from "react";
import Logo from "../../components/logo/logo";
import ProfilePic from "../../assets/images/profile-01.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard-icon.svg";
import { ReactComponent as OrderHistoryIcon } from "../../assets/images/order-history.svg";
import {
  AppBar,
  Avatar,
  Divider,
  Drawer,
  Grid,
  Input,
  Paper,
  TextField,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

import { ReactComponent as ProfileImgPlaceholder } from "../../assets/images/profile-01.svg";
import { variables } from "../../assets/scss/variables.js";
import CustomToggleButton from "../CustomToggleButton/index.js";
import { useLocation, useNavigate } from "react-router";
import { red } from "@mui/material/colors";
import { setCurrentOrderType, setCurrentUserType } from "../../store/reducers/orders";
import { useTranslation } from "react-i18next";

const LogoIcon = styled(Logo)({
  width: "6.75rem",
  hight: "6.75rem",
  "*": {
    // fill: "#FFFFFF !important",
  },
});
// Logo;

const ProfilePlaceholder = styled(ProfileImgPlaceholder)({
  width: "3.625rem",
  hight: "3.625rem",
  "*": {
    // fill: "#FFFFFF !important",
  },
});

const SideMenu = ({}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const  currentUserType  = useSelector((state) => state.orders.currentUserType) || parseInt(localStorage.getItem("currentUserType") || 0);
  const navbarItems = [
    {
      id: 0,
      icon: <DashboardIcon />,
      label: t("dashboard_title"),
      route: "/dashboard",
    },
    {
      id: 1,
      icon: <OrderHistoryIcon />,
      label: t("order_history_title"),
      route: "/order-history",
    },
  ];

  const [alignment, setAlignment] = useState(currentUserType);
  const [selectedToggleButton, setSelectedToggleButton] = useState(
    currentUserType
  );
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();

  const handleItemClick = (event, route, id) => {
    event.stopPropagation();
    event.preventDefault();
    // setSelectedItem(id);
    navigate(route);
  };

  const buttonNames = [
    { id: 0, label: "distributor", action: () => alert("Distributor") },
    { id: 1, label: "receiver", action: () => alert("Receiver") },
  ];

  useEffect(() => {
    dispatch(setCurrentUserType(selectedToggleButton));
  }, [selectedToggleButton]);

  useEffect(() => {
    setSelectedItem(selectedItem);
    dispatch(setCurrentOrderType(0));
  }, [selectedItem]);

  useEffect(() => {
    const currentRoute = navbarItems.find(
      (item) => item.route === location.pathname
    );
    if (currentRoute) {
      setSelectedItem(currentRoute.id);
    }
  }, [location, navbarItems]);

  return (
    <>
      <Grid
        container
        mt={3}
        mb={3}
        flexDirection="column"
        alignItems="center"
        sx={{ width: "23.4375rem" }}
      >
        <Grid item>
          <LogoIcon />
        </Grid>
      </Grid>
      <Divider />
      <Paper onClick={() => navigate("/profile")} className="cursor">
        <Grid
          container
          alignItems="center"
          mt={3}
          sx={{
            backgroundColor: variables.primaryColor,
            padding: "1rem 1.5rem",
            color: "white",
            fontFamily: "Poppins",
          }}
        >
          <Grid item>
            {user?.logo ? (
              <Avatar
                sx={{ width: "3.625rem", height: "3.625rem" }}
                src={user?.logo}
                alt=""
              />
            ) : (
              <ProfilePlaceholder />
            )}
          </Grid>
          <Grid item>
            <Grid
              pl={3}
              container
              flexDirection="column"
              alignItems="start"
              justifyContent="center"
            >
              <Grid item>
                <Typography className="profile-name" fontSize="18px">
                  {user?.distributor_name === ""
                    ? "Distributor"
                    : user?.distributor_name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="" className="profile-title" fontSize="12px">
                  {t("user_profile")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        mt={3}
        mb={2}
        sx={{ width: "100%" }}
        alignItems="center"
        className="ToggleButton"
      >
        <Grid item sx={{ width: "100%", paddingLeft: "1.3rem" }}>
          <CustomToggleButton
            buttonNames={buttonNames}
            disabled={user?.first_name === ""}
            alignment={alignment}
            setAlignment={setAlignment}
            setSelectedToggleButton={setSelectedToggleButton}
          />
        </Grid>
      </Grid>
      <List>
        {navbarItems.map((item) => {
          return (
            <ListItemButton
              key={item.id}
              onClick={(event) => handleItemClick(event, item.route, item.id)}
              disabled={user?.first_name === ""}
              disableTouchRipple
              selected={selectedItem === item.id}
              sx={{
                height: "4.75rem",
                paddingLeft: "2rem",
                backgroundColor: "#E7E7E7",
                color: "#808080",
                "&.Mui-selected": {
                  color: "white",
                  backgroundColor: variables.primaryColor,
                  "&:hover": {
                    backgroundColor: variables.primaryColor,
                  },
                },
              }}
            >
              <ListItemIcon color="#808080">{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          );
        })}
      </List>
    </>
  );
};

export default SideMenu;
