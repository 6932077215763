
export const ENGLISH_LANGUAGE = {
  dashboard_title: "Dashboard",
  order_history_title: "Order History",
  check_in_time: "Check in Time",
  driver: "Driver",
  plate_no: "Plate No",
  phone: "Phone",
  pick_up_no: "Pickup No",
  po_no: "PO No",
  status: "Status",
  gate_no: "Dock No",
  action: "Action",
  checked_in_order: "Checked In Orders",
  ongoing_order: "Ongoing Orders",
  completed_order: "Completed Orders",
  not_check_in_order: "You do not have any checked in orders",
  not_ongoing_order: "You do not have any ongoing orders",
  not_completed_order: "You do not have any completed orders",
  no_order: "No Order",
  at_moment: "at the moment",
  n_a: "N/A",
  view: "View",
  approve: "Approve",
  hold: "Hold",
  confirm_driver_arrival: "Confirm Driver Arrived",
  cancel: "Cancel",
  mark_driver_arrived: "Mark Driver Arrived",
  ready_for_loading: "Ready for Loading",
  ready_off_loading: "Ready for Unloading",
  halt_loading: "Halt Loading",
  halt_off_loading: "Halt Unloading",
  mark_delivered: "Delivery Complete",
  mark_loading_complete: "Mark Loading Complete",
  confirm_loading_complete: "Confirm Loading Complete",
  confirm_delivery: "Confirm Delivery",
  decline: "Decline",
  order_details_title: "Order Details",
  pick_up_status: "Pickup Status",
  pick_up_details: "Pick Up Details",
  pick_up_number: "Pick-up Number",
  carrier_name: "Carrier Name",
  trailer_plate_number: "Trailer Plate Number",
  driver_phone_number: "Driver Phone Number",
  po_number: "PO Number",
  driver_location: "Driver Location",
  driver_remarks: "Driver Remarks",
  check_in_remark: "Check-in remarks",
  arrival_remark: "Arrival remarks",
  loading_complete_remark: "Loading complete remarks",
  delivery_remark: "Delivery remarks",
  checked_in_instruction: "Check-In Instructions",
  instruction: "Instructions",
  loading_instruction: "Loading Instructions",
  loading_complete_instruction: "Loading Complete Instructions",
  off_loading_instruction: "Unloading Instructions",
  delivery_complete_instruction: "Delivery Complete Instructions",
  reason_for_decline: "Reason for Decline",
  reason_for_hold: "Reason for Hold",
  reason_for_halt: "Reason for Halt",
  reason: "Reason",
  allowed_gate: "Allowed Dock",
  add_new_instruction: "Add New Instruction",
  cancel_approval: "Press Submit to Cancel",
  driver_confirm_description: "This confirmation of Driver Arrival will also mark the order as approved.",
  loading_complete_description: "Loading Complete Instruct Driver to sign Bill of Lading or provide any additional instructions",
  delivery_complete_description: "Delivery Complete Instruct Driver to collect Delivery Documnets or provide any additional instructions",
  gate_placeholder: "Please type in the Dock Number",
  comment_placeholder: "Comment",
  submit: "Submit",
  header_search_placeholder: "Search by Name, Plate, Carrier, Pick-Up No.",
  notification: "Notification",
  view_all_notification: "View All",
  user_profile: "User Profile",
  distributor: "Distributor",
  receiver: "Receiver",
  checked_in_driver: " checked in drivers",
  ongoing_driver: " ongoing drivers",
  completed_driver: " completed drivers",
  out_of: "out of",
  of: "of",
  my_profile_title: "My Profile",
  first_name: "First name",
  last_name: "Last name",
  name: "name",
  warehouse_name: "Name of the warehouse",
  phone_number: "Phone number",
  email: "Email",
  location: "Location",
  set_location: "Set Location",
  hours_of_operation: "Hours of Operation",
  set_hours: "Set Hours",
  save: "Save",
  continue: "Continue",
  account_setting: "Account Settings",
  terms_and_conditions: "Terms and Conditions",
  privacy_policy: "Privacy Policy",
  my_subscription: "My Subscription",
  location_permission: "Allow Fast Trax access your location while using the app",
  yes: "Yes",
  confirm_location: "Confirm Location",
  same_hours_every_day: "Same hours every day",
  free: "Free",
  free_description: "Free subscription plan for 1 month",
  cancelled: "Cancelled",
  upgrade: "Upgrade",
  subscribe: "Subscribe",
  start: "Start",
  our_subscription_plans: "Our Subscription Plans",
  active: "Active",
  payment: "Payment",
  amount_to_be_deducted: "Amount to be deducted",
  cancel_subscription: "Cancel Subscription",
  cancel_subscription_description: "Are you sure you want to cancel your subscription?",
  no: "No",
  change_password: "Change Password",
  enter_new_password: "Please enter a new password",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  change_password_validation: "Must be at least 8 characters, one letter, one number and one special character",
  password_match: "Passwords must match",
  delete_account: "Delete Account",
  delete_account_description: "Are you sure you want to delete your account? You can't revert it back.",
  delete: "Delete",
  logout: "Logout",
  logout_description: "Are you sure you want to logout?",
  login: "Login",
  password: "Password",
  forgot_password: "Forgot Password",
  forgot_password_description: "Please enter your registered email to reset your password",
  dont_have_account: "Don't have an account",
  sign_up: "Sign Up",
  create_password: "Create password",
  by_using_fast_trax: "By using Fast Trax, you agree to our",
  and: "and",
  already_account: "Already have an account",
  reset: "Reset",
  remember_password: "Remember your password",
  check_email: "Check your email",
  verify: "Verify",
  not_received_otp: "Did not receive the OTP",
  resend: "Resend",
  update: "Update",
  password_success: "Password changed successfully",
  plan_purchased_successfully: "Plan purchased successfully",
  logged_in_successfully: "Logged in successfully",
  reset_password_success: "Password has been reset with the new password",
  registration_success: "Registered successful",
  profile_updated_success: "Profile updated successfully",
  subscription_cancelled_success: "Subscription plan cancelled successfully",
  subscription_upgraded_success: "Subscription plan upgraded successfully",
  subscription_free_success: "Free subscription plan activated successfully",
  something_went_wrong: "Something went wrong",
  payment_failed: "Payment failed",
  require_subscription: "Please purchase any subscription plan to continue",
  require_profile: "Please set up your profile first",
  send_feedback: "Send Feedback",
  feedback: "Feedback",
  feedback_sent: "Feedback sent successfully",
  feedback_placeholder: "Please enter your feedback here",
  check_in_request: "New Check In Request",
  driver_arrived: "Driver Arrived",
  loading_complete: "Loading Complete",
  delivery_complete: "Delivery Complete",
  instruction_if_any: "Instruction if any",
  read_all: "Read All",
  block_notification_alert: "Please enable notifications in your browser settings to receive notifications from us. You can do this by clicking on the icon left of the address/search bar and changing the permissions.",
  location_placeholder: "Setting your location allows Drivers to navigate your facility",
  hours_placeholder: "Your hours of operation will be viewed by Drivers in the app",
  pending_status: "Pending",
  confirmed_status: "Confirmed",
  declined_status: "Declined",
  on_hold_status: "On Hold",
  driver_arrived_status: "Driver Arrived",
  loading_paused_status: "Loading Paused",
  loading_status: "Loading",
  loading_complete_status: "Loading Complete",
  off_loading_paused_status: "Unloading Paused",
  off_loading_status: "Unloading",
  delivered_status: "Delivered",
}