
export const buttonNamesForOrderTypes = [
    {
      id: 0,
      label: "checked_in_order",
    },
    {
      id: 1,
      label: "ongoing_order",
    },
    {
      id: 2,
      label: "completed_order",
    },
];

export const orderStatusUtils = {
  color: {
    pending: "#ED893E",
    approved: "#2ECC71",
    declined: "#F47665",
    on_hold: "#E4BF44",
    "on_hold/driver_arrived": "#E4BF44",
    "approved/driver_arrived": "#2ECC71",
    loading_pending: "#1C894A",
    loading_paused: "#E4BF44",
    loading: "#E4BF44",
    "loading/loading_complete": "#E4BF44",
    loading_complete: "#2ECC71",
    off_loading_pending: "#1C894A",
    off_loading_paused: "#E4BF44",
    off_loading: "#E4BF44",
    "off_loading/delivered": "#E4BF44",
    delivered: "#2ECC71"
  },
  label: {
    pending: "pending_status",
    approved: "confirmed_status",
    declined: "declined_status",
    on_hold: "on_hold_status",
    "on_hold/driver_arrived": "on_hold_status",
    "approved/driver_arrived": "confirmed_status",
    loading_pending: "driver_arrived_status",
    loading_paused: "loading_paused_status",
    loading: "loading_status",
    "loading/loading_complete": "loading_status",
    loading_complete: "loading_complete_status",
    off_loading_pending: "driver_arrived_status",
    off_loading_paused: "off_loading_paused_status",
    off_loading: "off_loading_status",
    "off_loading/delivered": "off_loading_status",
    delivered: "delivered_status"
  },
};

export const filterByStatusLabels = [
  { id: "", name: "All" },
  { id: "pending", name: "Pending" },
  { id: "approved", name: "Approved" },
  { id: "declined", name: "Declined" },
  { id: "on_hold", name: "On Hold" },
  { id: "loading_pending", name: "Driver Arrived" },
];

export const toggleStatusForPagination = {
  0: "checked_in_driver",
  1: "ongoing_driver",
  2: "completed_driver",
};

export const OrderRemarkTitle = {
  pending: "check_in_remark",
  "approved/driver_arrived": "arrival_remark",
  "on_hold/driver_arrived": "arrival_remark",
  "loading/loading_complete": "loading_complete_remark",
  "off_loading/delivered": "delivery_remark",
};

export const instructionsUtils = {
  color: {
    pending: "#ED893E",
    approved: "#2ECC71",
    declined: "#F47665",
    on_hold: "#E4BF44",
    "on_hold/driver_arrived": "#E4BF44",
    "approved/driver_arrived": "#2ECC71",
    loading_pending: "#1C894A",
    loading_paused: "#E4BF44",
    loading: "#E4BF44",
    "loading/loading_complete": "#E4BF44",
    loading_complete: "#2ECC71",
    off_loading_pending: "#1C894A",
    off_loading_paused: "#E4BF44",
    off_loading: "#E4BF44",
    "off_loading/delivered": "#E4BF44",
    delivered: "#2ECC71"
  },
  label: {
    pending: "",
    approved: "checked_in_instruction",
    declined: "reason_for_decline",
    on_hold: "reason_for_hold",
    "on_hold/driver_arrived": "reason_for_hold",
    "approved/driver_arrived": "checked_in_instruction",
    loading_pending: "instruction",
    loading_paused: "reason_for_halt",
    loading: "loading_instruction",
    "loading/loading_complete": "loading_instruction",
    loading_complete: "loading_complete_instruction",
    off_loading_pending: "instruction",
    off_loading_paused: "reason_for_halt",
    off_loading: "off_loading_instruction",
    "off_loading/delivered": "off_loading_instruction",
    delivered: "delivery_complete_instruction"
  },
};

export const orderStatusType = {
  0 : "pickup_status",
  1 : "delivery_status"
}

export const popupEnum = {
  label: {
    approved: "checked_in_instruction",
    on_hold: "reason_for_hold",
    "on_hold/driver_arrived": "instruction",
    "approved/driver_arrived": "instruction",
    driver_arrived: "instruction",
    ready: "loading_instruction",
    loading_paused: "reason_for_halt",
    loading_complete: "loading_complete_instruction",
    declined: "reason_for_decline",
    cancel: "cancel_approval",
    loading: "loading_instruction",
    loading_delivered: "delivery_complete_instruction",
    ready_off_loading: "off_loading_instruction",
  },
};

export const notificationPopupEnum = {
  title: {
    pending: "check_in_request",
    "on_hold/driver_arrived": "driver_arrived",
    "approved/driver_arrived": "driver_arrived",
    "loading/loading_complete": "loading_complete",
    "off_loading/delivered": "delivery_complete",
  },
  instruction: {
    pending: "checked_in_instruction",
    "on_hold/driver_arrived": "instruction",
    "approved/driver_arrived": "instruction",
    "loading/loading_complete": "loading_complete_instruction",
    "off_loading/delivered": "delivery_complete_instruction",
  },
};
