import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AddNewInstructionButton = ( {handleClick}) => {
  const {t} = useTranslation();
    return (
      <Grid
            item
            onClick={handleClick}
            sx={{
              height: "4rem",
              width: "100%",
              border: "2px dashed #7363F8",
              borderRadius: "1.25rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "18px",
                color: "#7363F8",
              }}
            >
              {t("add_new_instruction")}
            </Typography>
          </Grid>
    )

}

export default AddNewInstructionButton;