import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiBuySubscriptionPlan, apiCancelSubscriptionPlan, apiGetSubscriptionPlans, apiUserSubscriptionPlans, apiUserTrialSubscriptionPlan } from "../../apis/subscription";
import { getServerError } from "../../utils/utils";


export const getSubscriptionPlans = createAsyncThunk(
  "subscription/getSubscriptionPlans",
  async () => {
    try {
      const response = await apiGetSubscriptionPlans();
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const userSubscriptionPlans = createAsyncThunk(
  "subscription/userSubscriptionPlans",
  async () => {
    try {
      const response = await apiUserSubscriptionPlans();
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const buySubscriptionPlans = createAsyncThunk(
  "subscription/buySubscriptionsPlans",
  async (payload) => {
    try {
      const response = await apiBuySubscriptionPlan(payload);
      return response.data;
    } catch (err) {
      console.log(err, "err");
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const cancelSubscriptionPlans = createAsyncThunk(
  "subscription/cancelSubscriptionsPlans",
  async () => {
    try {
      const response = await apiCancelSubscriptionPlan();
      return response.data;
    } catch (err) {
      console.log(err, "err");
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const  userTrialSubscriptionPlan = createAsyncThunk(
  "subscription/userTrialSubscriptionPlan",
  async () => {
    try {
      const response = await apiUserTrialSubscriptionPlan();
      return response.data;
    } catch (err) {
      console.log(err, "err");
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

const initialSubscriptionState = {
  subscriptionPlansApi: {data: [], loading: false, error: null},
  userSubscriptionPlansApi: {data: [], loading: true, error: null},
  buySubscriptionPlansApi: {data: [], loading: false, error: null},
  cancelSubscriptionPlansApi: {data: [], loading: false, error: null},
  userTrialSubscriptionPlanApi: {data: [], loading: false, error: null},
}

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialSubscriptionState,
  reducers: {},
  extraReducers: {
    [getSubscriptionPlans.pending]: (state) => {
      state.subscriptionPlansApi.loading = true;
    },
    [getSubscriptionPlans.fulfilled]: (state, action) => {
      state.subscriptionPlansApi.loading = false;
      state.subscriptionPlansApi.data = action.payload;
    },
    [getSubscriptionPlans.rejected]: (state, action) => {
      state.subscriptionPlansApi.loading = false;
      state.subscriptionPlansApi.data = action.error.message;
    },
    [userSubscriptionPlans.pending]: (state) => {
      state.userSubscriptionPlansApi.loading = true;
    },
    [userSubscriptionPlans.fulfilled]: (state, action) => {
      state.userSubscriptionPlansApi.loading = false;
      state.userSubscriptionPlansApi.data = action.payload;
    },
    [userSubscriptionPlans.rejected]: (state, action) => {
      state.userSubscriptionPlansApi.loading = false;
      state.userSubscriptionPlansApi.data = action.error.message;
    },
    [buySubscriptionPlans.pending]: (state) => {
      state.buySubscriptionPlansApi.loading = true;
    },
    [buySubscriptionPlans.fulfilled]: (state, action) => {
      state.buySubscriptionPlansApi.loading = false;
      state.buySubscriptionPlansApi.data = action.payload;
    },
    [buySubscriptionPlans.rejected]: (state, action) => {
      state.buySubscriptionPlansApi.loading = false;
      state.buySubscriptionPlansApi.data = action.error.message;
    },
    [cancelSubscriptionPlans.pending]: (state) => {
      state.cancelSubscriptionPlansApi.loading = true;
    },
    [cancelSubscriptionPlans.fulfilled]: (state, action) => {
      state.cancelSubscriptionPlansApi.loading = false;
      state.cancelSubscriptionPlansApi.data = action.payload;
    },
    [cancelSubscriptionPlans.rejected]: (state, action) => {
      state.cancelSubscriptionPlansApi.loading = false;
      state.cancelSubscriptionPlansApi.data = action.error.message;
    },
    [userTrialSubscriptionPlan.pending]: (state) => {
      state.userTrialSubscriptionPlanApi.loading = true;
    },
    [userTrialSubscriptionPlan.fulfilled]: (state, action) => {
      state.userTrialSubscriptionPlanApi.loading = false;
      state.userTrialSubscriptionPlanApi.data = action.payload;
    },
    [userTrialSubscriptionPlan.rejected]: (state, action) => {
      state.userTrialSubscriptionPlanApi.loading = false;
      state.userTrialSubscriptionPlanApi.data = action.error.message;
    },
  },
});
