import React, { useEffect, useState } from "react";
import PageLayout from "../../Layouts/PageLayouts";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getAllOrders } from "../../store/reducers/orders";
import { useDispatch } from "react-redux";
import { orderStatusUtils } from "../../utils/constants/order";
import Pagination from "../../components/TablePagination/Pagination";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EAEFF0",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: "2px solid #EAEFF0",
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledButton = styled(Button)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6.25rem",
  padding: "0.5rem 1rem",
  marginRight: "4px",
  disableElevation: true,
  width: "auto",
  textTransform: "capitalize",
});

const OrderHistory = () => {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const { loading } = useSelector((state) => state.orders.getAllOrdersApi);

  const  currentUserType  = useSelector((state) => state.orders.currentUserType) || parseInt(localStorage.getItem("currentUserType") || 0);

  const [statusConfig, setStatusConfig] = useState({
    params: {
      order: currentUserType === 0 ? "history" : "drop_history",
      search: "",
      page_size: 20,
      page: 1,
      filter: "",
    },
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  let searchTimer;

  const fetchData = async (statusConfig) => {
    dispatch(getAllOrders(statusConfig)).then((res) => {
      if (!res.error) {
        setRows(res.payload?.results);
        setCount(res.payload?.count);
      }
    });
  };

  useEffect(() => {
    fetchData(statusConfig);
  }, [statusConfig]);

  useEffect(() => {
    const config = { ...statusConfig };
    config.params.filter = "";
    config.params.page = 1;
    if (currentUserType === 0) {
      config.params.order = "history";
    } else if (currentUserType === 1) {
      config.params.order = "drop_history";
    }
    setStatusConfig(config);
  }, [currentUserType]);

  const handleChangeSearch = (event) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      const config = { ...statusConfig };
      config.params.search = event.target.value;
      setStatusConfig(config);
    }, 1000);
  };

  const handleChangePage = (name) => {
    const config = { ...statusConfig };
    if (config.params.page > 0 && name === "prev") {
      config.params.page = config.params.page - 1;
    } else if (name === "next") {
      config.params.page = config.params.page + 1;
    }
    setStatusConfig(config);
  };

  return (
    <PageLayout
      headerTitle={t("order_history_title")}
      showBackBtn={false}
      handleChange={handleChangeSearch}
    >
      <Grid container mt={4} sx={{ position: "fixed" }}>
        <Grid item pt={2} paddingLeft="1rem">
          <Paper
            sx={{ width: "100%", overflow: "hidden", borderRadius: "1rem" }}
          >
            <TableContainer
              style={{
                width: "73vw",
                height: "65vh",
                maxWidth: "90vw",
                overflowX: "auto",
              }}
              sx={{
                "&::-webkit-scrollbar": {
                  width: 9.65,
                  height: 9.65,
                },
                "&::-webkit-scrollbar-track": {
                  padding: "2px",
                  backgroundColor: "white",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#7B67FDA3",
                  width: 5,
                  borderRadius: 2,
                },
              }}
            >
              <Table
                stickyHeader
                sx={{
                  width: "max-content",
                  minWidth: "73vw",
                  height: "max-content",
                  maxHeight: "100vh",
                }}
                aria-label="customized table"
              >
                <TableHead
                  sx={{
                    backgroundColor: "#EAEFF0",
                    borderRadius: "1rem",
                  }}
                >
                  <TableRow>
                    <StyledTableCell
                      sx={{ borderTopLeftRadius: "1rem" }}
                      align="left"
                    >
                      {t("check_in_time")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {t("driver")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {t("plate_no")}
                    </StyledTableCell>
                    <StyledTableCell align="left">{t("phone")}</StyledTableCell>
                    <StyledTableCell align="left">
                      {t("carrier_name")}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {t("pick_up_no")}
                    </StyledTableCell>
                    <StyledTableCell align="left">{t("po_no")}</StyledTableCell>
                    <StyledTableCell align="left">
                      {t("status")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ borderTopRightRadius: "1rem" }}
                      align="left"
                    >
                      {t("action")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={7}
                        align="center"
                        // text
                        style={{ width: "100%" }}
                      >
                        <CircularProgress sx={{ margin: "10rem auto" }} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : rows?.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={7}
                        align="center"
                        // text
                        style={{ height: "65vh", width: "100%" }}
                      >
                        <Typography
                          component={"div"}
                          sx={{ fontWeight: 500, fontSize: "1.25rem" }}
                        >
                          {t("not_completed_order")}
                          <br /> {t("at_moment")}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    rows?.map((row) => (
                      <StyledTableRow key={row?.id}>
                        <StyledTableCell component="th" scope="row">
                          {dayjs(row?.created_at)
                            .format("MMM D, YYYY HH:mm")}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography
                            textTransform="capitalize"
                            color="#7363F8"
                          >
                            {row?.driver?.first_name +
                              " " +
                              row?.driver?.last_name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.driver?.license_plate_number}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.driver?.country_code+ "-" + row?.driver?.phone_number}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography
                            component={"div"}
                            textTransform="capitalize"
                          >
                            {row?.driver?.carrier_name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.pickup_number || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.po_number || "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography
                            component={"div"}
                            textTransform="capitalize"
                            sx={{
                              color:
                                currentUserType === 0
                                  ? `${
                                      orderStatusUtils?.color[
                                        row?.pickup_status
                                      ]
                                    }`
                                  : `${
                                      orderStatusUtils?.color[
                                        row?.delivery_status
                                      ]
                                    }`,
                            }}
                          >
                            {currentUserType === 0
                              ? t(orderStatusUtils?.label[row?.pickup_status])
                              : t(orderStatusUtils?.label[row?.delivery_status])}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <StyledButton
                            onClick={() =>
                              navigate("/ViewDetails", {
                                state: {
                                  data: row?.id,
                                  currentUserType: currentUserType,
                                },
                              })
                            }
                            variant="contained"
                            sx={{
                              backgroundColor: "#7363F8",
                            }}
                          >
                            {t("view")}
                          </StyledButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={count}
              rowsPerPage={statusConfig.params.page_size}
              page={statusConfig.params.page}
              handlePageNavigation={handleChangePage}
              currentToggle={2}
            />
          </Paper>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default OrderHistory;
