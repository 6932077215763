import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { toast } from "react-toastify";

const FailurePayment = () => {
  const {t} = useTranslation();
  toast.error(t("payment_failed"))
  return(
    <Navigate to="/subscription" />
  )
};

export default FailurePayment;
