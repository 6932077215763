import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicy } from "../../store/reducers/auth";
import PageLayout from "../../Layouts/PageLayouts";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { api, privacyPolicy } = useSelector((state) => state.auth);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  return (
    <PageLayout
      headerTitle={t("privacy_policy")}
      showBackBtn={true}
      showSearchField={false}
    >
      <div className="profile-container">
      </div>
      <div className="profile-content">
        <div
          className="privacy-data"
          dangerouslySetInnerHTML={{ __html: privacyPolicy?.body }}
        ></div>
      </div>
    </PageLayout>
  );
};

export default PrivacyPolicy;
