import GoogleMapReact from "google-map-react";
import { useState, useEffect } from "react";
import { GOOGLE_API_KEY } from "../../utils/constants/key";
import { useDispatch } from "react-redux";
import { getDriverLocation } from "../../store/reducers/orders";
import ReactDOMServer from "react-dom/server";
import InfoWindowContent from "../../components/InfoWindowContent/InfoWindowContent";
import { useTranslation } from "react-i18next";

export const OrderMap = ({ distributorDetails, driverDetails }) => {
  const { t } = useTranslation();
  const [distributorLocation, setDistributorLocation] = useState();
  const [driverLocation, setDriverLocation] = useState();
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [originInfoWindow, setOriginInfoWindow] = useState(null);
  const [destinationInfoWindow, setDestinationInfoWindow] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDriverLocation = () => {
      if (driverDetails?.id) {
        dispatch(getDriverLocation(driverDetails?.id)).then((res) => {
          if (!res.error) {
            setDriverLocation({
              lat: res.payload?.latitude,
              lng: res.payload?.longitude,
            });
          }
        });
      }
    };

    fetchDriverLocation();
    const intervalId = setInterval(fetchDriverLocation, 180000);
    return () => clearInterval(intervalId);
  }, [driverDetails]);

  useEffect(() => {
    setDistributorLocation({
      lat: isNaN(parseFloat(distributorDetails?.latitude))
        ? null
        : parseFloat(distributorDetails?.latitude),
      lng: isNaN(parseFloat(distributorDetails?.longitude))
        ? null
        : parseFloat(distributorDetails?.longitude),
    });
  }, [distributorDetails]);

  useEffect(() => {
    if (driverLocation && distributorLocation && map && maps) {
      if (originInfoWindow && destinationInfoWindow) {
        destinationInfoWindow.setPosition(distributorLocation);
        destinationInfoWindow.open(map);
        originInfoWindow.setPosition(driverLocation);
        originInfoWindow.open(map);
      }

      if (directionsRenderer && directionsService) {
        directionsRenderer.setMap(map);
        directionsService.route(
          {
            origin: driverLocation,
            destination: distributorLocation,
            travelMode: "DRIVING",
          },
          (response, status) => {
            if (status === "OK") {
              // If the status is OK, render the directions on the map
              directionsRenderer.setDirections(response);
            } else {
              console.error(`Error occurred in creating directions: ${status}`);
            }
          }
        );
      }
    }
  }, [
    driverLocation,
    distributorLocation,
    map,
    maps,
    directionsService,
    directionsRenderer,
    originInfoWindow,
    destinationInfoWindow,
  ]);

  const renderMarkers = (map, maps) => {
    const marker = new maps.Marker({
      position: distributorLocation,
      map,
      icon: {
        url: require("../../assets/images/map-marker.png"),
        scaledSize: new maps.Size(0, 0),
        anchor: new maps.Point(25, 25),
      },
    });
    const marker2 = new maps.Marker({
      position: driverLocation,
      map,
      icon: {
        url: require("../../assets/images/map-marker.png"),
        scaledSize: new maps.Size(0, 0),
        anchor: new maps.Point(25, 25),
      },
    });

    const newOriginInfoWindow = new maps.InfoWindow({
      content: ReactDOMServer.renderToString(
        <InfoWindowContent
          profile_picture={driverDetails?.profile_picture}
          name={driverDetails?.first_name + " " + driverDetails?.last_name}
          role={t("driver")}
        />
      ),
    });
    setOriginInfoWindow(newOriginInfoWindow);

    const newDestinationInfoWindow = new maps.InfoWindow({
      content: ReactDOMServer.renderToString(
        <InfoWindowContent
          profile_picture={distributorDetails?.logo}
          name={
            distributorDetails?.first_name + " " + distributorDetails?.last_name
          }
          role={t("distributor")}
        />
      ),
    });
    setDestinationInfoWindow(newDestinationInfoWindow);

    const newDirectionsService = new maps.DirectionsService();
    setDirectionsService(newDirectionsService);

    const newDirectionsRenderer = new maps.DirectionsRenderer({
      suppressMarkers: true,
      preserveViewport: true,
    });
    setDirectionsRenderer(newDirectionsRenderer);
  };

  return (
    <div
      style={{
        height: "calc(100vh - 400px)",
        width: "100%",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_API_KEY,
          libraries: ["places"],
        }}
        center={driverLocation}
        defaultZoom={15}
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          setMaps(maps);
          renderMarkers(map, maps);
        }}
        yesIWantToUseGoogleMapApiInternals
      ></GoogleMapReact>
    </div>
  );
};
