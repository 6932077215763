import httpClient from "../utils/httpClient";

export function apiSignupRequest(payload) {
  return httpClient.post(`/api/v1/signup/`, payload, {
    headers: { Authorization: "" },
  });
}

export function apiLoginRequest(payload) {
  return httpClient.post(`/api/v1/login/`, payload, {
    headers: { Authorization: "" },
  });
}

export function apiAuthUserRequest() {
  return httpClient.get(`/rest-auth/user/`);
}

export function apiPasswordResetRequest(payload) {
  return httpClient.post(`/rest-auth/password/reset/otp/`, payload, {
    headers: { Authorization: "" },
  });
}

export function apiGetPrivacyPolicyRequest() {
  return httpClient.get(`/modules/privacy-policy/`);
}

export function apiGetTermsConditionRequest() {
  return httpClient.get(`/modules/terms-and-conditions/`);
}

export function apiUploadLogoRequest(payload) {
  const data = new FormData();
  data["image"] = payload.image;
  return httpClient.post(`/modules/camera/upload_image/`, data);
}

export function apiDeleteAccountRequest() {
  return httpClient.delete(`/rest-auth/user/delete/`);
}

export function apiGetLogoRequest(payload) {
  return httpClient.get(`/modules/photos/user/4/`);
}

export function apiPostDistributorRequest(payload) {
  const form = new FormData();
  form.append("first_name", payload.first_name);
  form.append("last_name", payload.last_name);
  form.append("phone_number", payload.phone_number);
  form.append("country_code", payload.country_code);
  form.append("warehouse_phone_number", payload.warehouse_phone_number);
  form.append("distributor_name", payload.distributor_name);
  form.append("warehouse_name", payload.warehouse_name);
  form.append("address", payload.address);
  form.append("email", payload.email);
  form.append("logo", payload.logo);
  form.append(`schedules`, JSON.stringify(payload.schedules));
  form.append("latitude", payload.latitude);
  form.append("longitude", payload.longitude);

  return httpClient.post(`/api/v1/distributors/`, form, {
    headers: {
      "Content-Type": `multipart/form-data;`,
    },
  });
}

export function apiGetDistributorRequest(id) {
  return httpClient.get(`/api/v1/distributors/${id}/`);
}

export function apiUpdateDistributorRequest(payload) {
  const form = new FormData();
  form.append("first_name", payload.first_name);
  form.append("last_name", payload.last_name);
  form.append("phone_number", payload.phone_number);
  form.append("country_code", payload.country_code);
  form.append("warehouse_phone_number", payload.warehouse_phone_number);
  form.append("distributor_name", payload.distributor_name);
  form.append("warehouse_name", payload.warehouse_name);
  form.append("address", payload.address);
  form.append("email", payload.email);
  form.append("id", payload.id);
  form.append("latitude", payload.latitude);
  form.append("longitude", payload.longitude);
  if (typeof payload.logo !== "string") {
    form.append("logo", payload.logo);
  }
  form.append(`schedules`, JSON.stringify(payload.schedules));

  return httpClient.put(`/api/v1/distributors/${payload.id}/`, form, {
    headers: {
      "Content-Type": `multipart/form-data;`,
    },
  });
}

export function apiChangePasswordRequest(payload) {
  return httpClient.post(`/change-password/`, payload);
}

export function apiVerifyOtpRequest(payload) {
  return httpClient.post(`/rest-auth/verify-otp/`, payload, {
    headers: { Authorization: "" },
  });
}

export function apiNewPasswordRequest(payload) {
  const { uidb64, token, ...rest } = payload;
  return httpClient.post(`/new-password/${uidb64}/${token}/`, rest, {
    headers: { Authorization: "" },
  });
}

export function apiSendFeedback(payload) {
  return httpClient.post(`/api/v1/feedbacks/`, payload);
}

export function apiLogout() {
  return httpClient.post(`/api/v1/logout/`);
}
