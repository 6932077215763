export const Visibility = () => {
  return <img src={require('../../assets/images/eye.png')} style={{ height: 21, width: 21 }} />
}

export const VisibilityOff = () => {
  return <img src={require('../../assets/images/hidden.png')} style={{ height: 21, width: 21 }} />
}

export const ChevronDown = (props) => {
  return <img src={require('../../assets/images/chevronDown.png')} {...props} />
}