import * as React from 'react';
import {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress, Grid, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import {
  orderStatusUtils,
  filterByStatusLabels,
} from '../../utils/constants/order';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ActionButton from '../DashboardActionButton';
import ActionModal from '../DashboardActionModal';
import {toast} from 'react-toastify';
import SingleSelectCheckmarks from '../singleselectcheckbox';
import Pagination from '../TablePagination/Pagination';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {updateOrderStatus} from '../../store/reducers/orders';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EAEFF0',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  border: '2px solid #EAEFF0',
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTables({
  selectedToggleButton,
  setSelectedToggleButton,
  allOrdersData,
  refetch,
  setAlignment,
  statusConfig,
  setStatusConfig,
  loading,
  currentUserType,
}) {
  const {user} = useSelector(state => state.auth);

  const [actionModal, setActionModal] = useState(false);
  const [rows, setRows] = useState([]);
  const {t} = useTranslation();

  const [payloadForActionModal, setPayloadForActionModal] = useState({});
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    setRows(allOrdersData?.results);
    setCount(allOrdersData?.count);
  }, [allOrdersData]);

  useEffect(() => {
    setRows(rows);
    setCount(count);
  }, [rows]);

  const clickHandler = commentData => {
    const dataForPickup = {
      payload: {
        pickup_comment: commentData,
        pickup_status: payloadForActionModal.order_status,
        is_message: payloadForActionModal?.is_message ? true : false,
      },
    };
    const dataForDelivery = {
      payload: {
        delivery_comment: commentData,
        delivery_status: payloadForActionModal.order_status,
        is_message: payloadForActionModal?.is_message ? true : false,
      },
    };
    const data = {
      id: payloadForActionModal.id,
      payload:
        currentUserType === 0
          ? {...dataForPickup.payload}
          : {...dataForDelivery.payload},
    };

    const updateStatus = async () => {
      try {
        await dispatch(updateOrderStatus(data))
          .then(res => {
            if (!res?.error) {
              console.log('Status Changed successfully');
              // toast.success("Status Changed successfully");
            } else {
              toast.error(res?.error?.message || t('something_went_wrong'));
            }
          })
          .catch(err => {
            console.log(err);
          });
        setActionModal(false);
        if (
          payloadForActionModal.order_status === 'loading' ||
          payloadForActionModal.order_status === 'off_loading'
        ) {
          if (selectedToggleButton === 1) {
            refetch(statusConfig);
          } else {
            setAlignment(1);
            setSelectedToggleButton(1);
          }
        } else if (
          payloadForActionModal.order_status === 'loading_complete' ||
          payloadForActionModal.order_status === 'delivered'
        ) {
          setAlignment(2);
          setSelectedToggleButton(2);
        } else if (
          payloadForActionModal.order_status === 'loading_paused' ||
          payloadForActionModal.order_status === 'off_loading_paused'
        ) {
          if (selectedToggleButton === 0) {
            refetch(statusConfig);
          } else {
            setAlignment(0);
            setSelectedToggleButton(0);
          }
        } else if (payloadForActionModal.order_status === 'declined') {
          if (selectedToggleButton === 0) {
            refetch(statusConfig);
          } else {
            setAlignment(0);
            setSelectedToggleButton(0);
          }
        } else {
          refetch(statusConfig);
        }
      } catch (e) {
        console.log(e);
        toast.error(t('something_went_wrong'));
      }
    };
    updateStatus();
  };

  const handleOrderingChange = e => {
    const config = {...statusConfig};
    config.params.filter = e.id;
    setStatusConfig(config);
  };

  const handleChangePage = name => {
    const config = {...statusConfig};
    if (config.params.page > 0 && name === 'prev') {
      config.params.page = config.params.page - 1;
    } else if (name === 'next') {
      config.params.page = config.params.page + 1;
    }
    setStatusConfig(config);
  };

  return (
    <Paper sx={{width: '100%', overflow: 'hidden', borderRadius: '1rem'}}>
      <TableContainer
        style={{
          width: '73vw',
          height: '65vh',
          maxWidth: '90vw',
          overflowX: 'auto',
        }}
        sx={{
          '&::-webkit-scrollbar': {
            width: 9.65,
            height: 9.65,
          },
          '&::-webkit-scrollbar-track': {
            padding: '2px',
            backgroundColor: 'white',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7B67FDA3',
            width: 5,
            borderRadius: 2,
          },
        }}>
        <Table
          stickyHeader
          sx={{
            width: 'max-content',
            minWidth: '73vw',
            height: 'max-content',
            maxHeight: '100vh',
          }}
          aria-label="customized table">
          <TableHead
            sx={{
              backgroundColor: '#EAEFF0',
              borderRadius: '1rem',
            }}>
            <TableRow>
              <StyledTableCell
                sx={{
                  borderTopLeftRadius: '1rem',
                }}
                align="left">
                {t('check_in_time')}
              </StyledTableCell>
              <StyledTableCell align="left">{t('driver')}</StyledTableCell>
              <StyledTableCell align="left">{t('plate_no')}</StyledTableCell>
              <StyledTableCell align="left">{t('phone')}</StyledTableCell>
              <StyledTableCell align="left">
                {t('carrier_name')}
              </StyledTableCell>
              {currentUserType === 0 && (
                <StyledTableCell align="left">
                  {t('pick_up_no')}
                </StyledTableCell>
              )}
              {currentUserType === 1 && (
                <StyledTableCell align="left">{t('po_no')}</StyledTableCell>
              )}
              <StyledTableCell align="left">
                {selectedToggleButton === 2 ? (
                  currentUserType == 0 && t('po_no')
                ) : (
                  <>
                    {selectedToggleButton === 1 ? (
                      t('status')
                    ) : (
                      <SingleSelectCheckmarks
                        data={filterByStatusLabels}
                        handleOrderingChange={handleOrderingChange}
                      />
                    )}
                  </>
                )}
              </StyledTableCell>
              {selectedToggleButton === 1 && (
                <StyledTableCell align="left">{t('gate_no')}</StyledTableCell>
              )}
              <StyledTableCell
                sx={{
                  borderTopRightRadius: '1rem',
                }}
                align="left">
                {t('action')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={7}
                  align="center"
                  // text
                  style={{width: '100%'}}>
                  <CircularProgress sx={{margin: '10rem auto'}} />
                </StyledTableCell>
              </StyledTableRow>
            ) : rows?.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell
                  colSpan={7}
                  align="center"
                  // text
                  style={{height: '65vh', width: '100%'}}>
                  <Typography
                    component={'div'}
                    sx={{fontWeight: 500, fontSize: '1.25rem'}}>
                    {selectedToggleButton === 0
                      ? t('not_check_in_order')
                      : selectedToggleButton === 1
                      ? t('not_ongoing_order')
                      : selectedToggleButton === 2
                      ? t('not_completed_order')
                      : t('no_order')}
                    <br /> {t('at_moment')}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              rows?.map(row => (
                <StyledTableRow key={row?.id}>
                  <StyledTableCell component="th" scope="row">
                    {dayjs(row?.created_at).format('MMM D, YYYY HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography textTransform="capitalize" color="#7363F8">
                      {row?.driver?.first_name + ' ' + row?.driver?.last_name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.driver?.license_plate_number}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.driver?.country_code +
                      '-' +
                      row?.driver?.phone_number}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography component={'div'} textTransform="capitalize">
                      {row?.driver?.carrier_name}
                    </Typography>
                  </StyledTableCell>
                  {currentUserType === 0 && (
                    <StyledTableCell align="left">
                      {row?.pickup_number || 'N/A'}
                    </StyledTableCell>
                  )}
                  {currentUserType === 1 && (
                    <StyledTableCell align="left">
                      {row?.po_number || 'N/A'}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="left">
                    {selectedToggleButton === 2 ? (
                      currentUserType === 0 && (row?.po_number || 'N/A')
                    ) : (
                      <Typography
                        component={'div'}
                        sx={{
                          color:
                            currentUserType === 0
                              ? `${orderStatusUtils?.color[row?.pickup_status]}`
                              : `${
                                  orderStatusUtils?.color[row?.delivery_status]
                                }`,
                        }}>
                        <Typography
                          component={'div'}
                          textTransform="capitalize">
                          {currentUserType === 0
                            ? t(orderStatusUtils?.label[row?.pickup_status])
                            : t(orderStatusUtils?.label[row?.delivery_status])}
                        </Typography>
                      </Typography>
                    )}
                  </StyledTableCell>
                  {selectedToggleButton === 1 && (
                    <StyledTableCell align="left">
                      {currentUserType === 0
                        ? row?.pickup_comments?.loading?.[0]?.gate || 'N/A'
                        : row?.delivery_comments?.off_loading?.[0]?.gate ||
                          'N/A'}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="left">
                    <Grid container justifyContent="left" alignItems="center">
                      <ActionButton
                        row={row}
                        setActionModal={setActionModal}
                        setPayloadForActionModal={setPayloadForActionModal}
                        view={true}
                        setSelectedToggleButton={setSelectedToggleButton}
                        currentUserType={currentUserType}
                      />
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={count}
        rowsPerPage={statusConfig.params.page_size}
        page={statusConfig.params.page}
        handlePageNavigation={handleChangePage}
        currentToggle={selectedToggleButton}
      />
      <ActionModal
        open={actionModal}
        setOpen={setActionModal}
        title={payloadForActionModal.label}
        clickHandler={clickHandler}
        gateNo={payloadForActionModal.gate_no}
      />
    </Paper>
  );
}
