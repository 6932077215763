import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Avatar, Divider, Grid, TextField, styled} from '@mui/material';
import CloseICon from '../../assets/images/close-icon.svg';
import {variables} from '../../assets/scss/variables';
import {ReactComponent as PoNumberImg} from '../../assets/images/PoNumber.svg';
import {ReactComponent as PhoneIconImg} from '../../assets/images/PhoneIcon.svg';
import {ReactComponent as TrailerPlateIconImg} from '../../assets/images/PlateIcon.svg';
import {ReactComponent as ProfileImgPlaceholder} from '../../assets/images/profile-01.svg';
import {ReactComponent as TruckImg} from '../../assets/images/TruckForModalImg.svg';
import {ReactComponent as TruckIconImg} from '../../assets/images/TruckImg.svg';
import {Button} from '../CustomButton';
import {useTranslation} from 'react-i18next';
import {notificationPopupEnum} from '../../utils/constants/order';

const StyledModal = styled(Modal)({});

const CardTypography = styled(Typography)({
  fontFamily: 'poppins',
  fontSize: '1rem',
  color: '#989CB8',
  fontWeight: '500',
  lineHeight: '18px',
  overflowWrap: 'anywhere',
});

const StyledTruckImg = styled(TruckImg)({
  width: '5.3rem',
  hight: '4.5rem',
  '*': {
    // fill: "#FFFFFF !important",
  },
});

const PhoneIcon = styled(PhoneIconImg)({
  width: '1.375rem',
  hight: '1.375rem',
  '*': {
    // fill: "#FFFFFF !important",
  },
});

const TrailerPlateIcon = styled(TrailerPlateIconImg)({
  width: '1.375rem',
  hight: '1.375rem',
  '*': {
    // fill: "#FFFFFF !important",
  },
});

const TruckIcon = styled(TruckIconImg)({
  width: '1.375rem',
  hight: '1.375rem',
  '*': {
    // fill: "#FFFFFF !important",
  },
});

const ProfilePlaceholder = styled(ProfileImgPlaceholder)({
  width: '3.625rem',
  height: '3.625rem',
  '*': {
    // fill: "#FFFFFF !important",
  },
});

const NewCheckInReqModal = ({
  open,
  onClose,
  data,
  setPayloadForActionModal,
  clickHandler,
  currentUserType,
}) => {
  const [comment, setComment] = useState('');
  const {t} = useTranslation();

  const [orderStatus, setOrderStatus] = useState(data?.pickup_status);
  const [driverRemark, setDriverRemark] = useState(data?.driver_pickup_remark);
  const [orderComments, setOrderComments] = useState(data?.pickup_comment);
  const [currentPopupPayload, setCurrentPopupPayload] = useState({});

  useEffect(() => {
    if (currentUserType === 0) {
      setOrderStatus(data?.pickup_status);
      setDriverRemark(data?.driver_pickup_remark);
      setOrderComments(data?.pickup_comments);
    } else {
      setOrderStatus(data?.delivery_status);
      setDriverRemark(data?.driver_delivery_remark);
      setOrderComments(data?.delivery_comments);
    }
  }, [data, currentUserType]);

  const handleSubmit = e => {
    e.preventDefault();
    clickHandler(currentPopupPayload);
  };

  return (
    <>
      <StyledModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Grid
          container
          flexDirection="column"
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            boxShadow: 24,
            padding: '1rem 1.5rem',
            boxSizing: 'border-box',
            width: '27rem',
            background: 'white',
            borderRadius: '1.5rem',
          }}>
          <Grid item textAlign="right">
            <span onClick={onClose} style={{cursor: 'pointer'}}>
              <img src={CloseICon} alt="" />
            </span>
          </Grid>
          <Grid item textAlign="center" marginBottom={2}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '1.5rem',
                color: '#2B2B2B',
                fontWeight: '600',
              }}>
              {currentUserType === 0 ? t('distributor') : t('receiver')}
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <StyledTruckImg />
          </Grid>
          <Grid item>
            <Grid
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <Grid item textAlign="center">
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '1.5rem',
                    color: '#2B2B2B',
                    fontWeight: '600',
                  }}>
                  {t(notificationPopupEnum.title[orderStatus])}
                </Typography>
                {orderStatus === 'on_hold/driver_arrived' && (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '1rem',
                      color: '#2B2B2B',
                      fontWeight: '600',
                    }}>
                    {t('driver_confirm_description')}
                  </Typography>
                )}
                {orderStatus === 'loading/loading_complete' && (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '1rem',
                      color: '#2B2B2B',
                      fontWeight: '600',
                    }}>
                    {t('loading_complete_description')}
                  </Typography>
                )}
                {orderStatus === 'off_loading/loading_delivered' && (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '1rem',
                      color: '#2B2B2B',
                      fontWeight: '600',
                    }}>
                    {t('delivery_complete_description')}
                  </Typography>
                )}
              </Grid>
              <Grid item textAlign="">
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    color: '#989CB8',
                    fontWeight: '600',
                    lineHeight: '18px',
                    paddingBottom: '0.4rem',
                  }}>
                  {currentUserType === 0 ? t('pick_up_number') : t('po_number')}{' '}
                  -
                  <span style={{color: '#7B67FD'}}>
                    {currentUserType === 0
                      ? data?.pickup_number
                      : data?.po_number}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              gap={1}
              flexDirection="column"
              justifyContent="center">
              <Grid item display="flex" alignItems="center">
                {data?.driver ? (
                  <Avatar src={data?.driver?.profile_picture} alt="" />
                ) : (
                  <ProfilePlaceholder />
                )}
                <CardTypography
                  sx={{
                    color: variables.primaryColor,
                    marginLeft: '0.5rem',
                    fontSize: '1.25rem',
                    textTransform: 'capitalize',
                    fontWeight: '600',
                  }}>
                  {data?.driver?.first_name + ' ' + data?.driver?.last_name}
                </CardTypography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" gap={2}>
                  <Grid item>
                    <TruckIcon />
                  </Grid>
                  <Grid item>
                    <CardTypography
                      sx={{fontSize: '0.75rem', fontWeight: '500'}}>
                      {t('carrier_name')}
                    </CardTypography>
                    <CardTypography
                      sx={{
                        color: '#000000',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                        textTransform: 'capitalize',
                      }}>
                      {data?.driver?.carrier_name}
                    </CardTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" gap={2}>
                  <Grid item>
                    <TrailerPlateIcon />
                  </Grid>
                  <Grid item>
                    <CardTypography
                      sx={{fontSize: '0.75rem', fontWeight: '500'}}>
                      {t('trailer_plate_number')}
                    </CardTypography>
                    <CardTypography
                      sx={{
                        color: '#000000',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                      }}>
                      {data?.driver?.license_plate_number}
                    </CardTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" gap={2}>
                  <Grid item>
                    <PhoneIcon />
                  </Grid>
                  <Grid item>
                    <CardTypography
                      sx={{fontSize: '0.75rem', fontWeight: '500'}}>
                      {t('driver_phone_number')}
                    </CardTypography>
                    <CardTypography
                      sx={{
                        color: '#000000',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                      }}>
                      {data?.driver?.phone_number}
                    </CardTypography>
                  </Grid>
                  <Grid container flexDirection="column" gap={1}>
                    <Grid item>
                      <CardTypography>{t('driver_remarks')}</CardTypography>
                    </Grid>
                    <Grid item>
                      <CardTypography sx={{color: '#000000'}}>
                        {driverRemark?.[0]?.remarks}
                      </CardTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{margin: '0 3rem'}} />
              <form onSubmit={handleSubmit}>
                <Grid item sx={{width: '100%', backgroundColor: ''}}>
                  <Grid container sx={{width: '100%', backgroundColor: ''}}>
                    <Grid
                      item
                      sx={{
                        margin: '0rem auto',
                      }}>
                      <Typography sx={{fontSize: '1.25rem', fontWeight: '500'}}>
                        {t(notificationPopupEnum.instruction[orderStatus])}
                      </Typography>
                    </Grid>
                    <Grid item sx={{width: '100%'}}>
                      <Typography>{t('comment_placeholder')}*</Typography>
                      <TextField
                        fullWidth
                        label={t('instruction_if_any')}
                        variant="standard"
                        onChange={e => setComment(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{marginTop: '5px'}}>
                  <Grid container gap={1}>
                    {orderStatus === 'pending' && (
                      <>
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            backgroundColor: '#2ECC71',
                            textTransform: 'capitalize',
                          }}
                          label={t('approve')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'approved',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'approved',
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            backgroundColor: '#F47665',
                            textTransform: 'capitalize',
                          }}
                          label={t('decline')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'declined',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'declined',
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            backgroundColor: '#E4BF44',
                            textTransform: 'capitalize',
                          }}
                          label={t('hold')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'on_hold',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'hold',
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                      </>
                    )}
                    {orderStatus === 'on_hold/driver_arrived' && (
                      <>
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            backgroundColor: '#2ECC71',
                            textTransform: 'capitalize',
                          }}
                          label={t('confirm_driver_arrival')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status:
                                currentUserType === 0
                                  ? 'loading_pending'
                                  : 'off_loading_pending',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'confirm',
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            color: '#F47665',
                            backgroundColor: 'white',
                            textTransform: 'capitalize',
                          }}
                          label={t('no')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'on_hold',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'cancel',
                              is_message: true,
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                      </>
                    )}
                    {orderStatus === 'approved/driver_arrived' && (
                      <>
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            backgroundColor: '#2ECC71',
                            textTransform: 'capitalize',
                          }}
                          label={t('confirm_driver_arrival')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status:
                                currentUserType === 0
                                  ? 'loading_pending'
                                  : 'off_loading_pending',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'confirm',
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            color: '#F47665',
                            backgroundColor: 'white',
                            textTransform: 'capitalize',
                          }}
                          label={t('no')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'approved',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'cancel',
                              is_message: true,
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                      </>
                    )}
                    {orderStatus === 'loading/loading_complete' && (
                      <>
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            backgroundColor: '#2ECC71',
                            textTransform: 'capitalize',
                          }}
                          label={t('confirm_loading_complete')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'loading_complete',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'confirm',
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            color: '#F47665',
                            backgroundColor: 'white',
                            textTransform: 'capitalize',
                          }}
                          label={t('no')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'loading',
                              order_comment: {
                                comment: comment,
                                gate: orderComments?.loading?.[0]?.gate || '',
                              },
                              label: 'cancel',
                              is_message: true,
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                      </>
                    )}
                    {orderStatus === 'off_loading/delivered' && (
                      <>
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            backgroundColor: '#2ECC71',
                            textTransform: 'capitalize',
                          }}
                          label={t('confirm_delivery')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'delivered',
                              order_comment: {
                                comment: comment,
                                gate: '',
                              },
                              label: 'confirm',
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                        <Button
                          type="submit"
                          sx={{
                            width: '100%',
                            color: '#F47665',
                            backgroundColor: 'white',
                            textTransform: 'capitalize',
                          }}
                          label={t('no')}
                          onClick={() => {
                            const popupPayload = {
                              driver_id: data.driver.id,
                              id: data.id,
                              order_status: 'off_loading',
                              order_comment: {
                                comment: comment,
                                gate:
                                  orderComments?.off_loading?.[0]?.gate || '',
                              },
                              label: 'cancel',
                              is_message: true,
                            };
                            setCurrentPopupPayload(popupPayload);
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </StyledModal>
    </>
  );
};

export default NewCheckInReqModal;
