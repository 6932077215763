import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getServerError } from "../../utils/utils";
import {
  apiGetAllNotification,
  apiGetAllOrders,
  apiGetDriverLocation,
  apiGetFilteredOrders,
  apiGetOrder,
  apiUpdateNotification,
  apiUpdateOrderStatus,
} from "../../apis/orders";

export const getAllOrders = createAsyncThunk(
  "orders/getAllOrders",
  async (payload) => {
    try {
      const response = await apiGetAllOrders(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const getOrder = createAsyncThunk("orders/getOrder", async (id) => {
  try {
    const response = await apiGetOrder(id);
    return response.data;
  } catch (err) {
    let errorMsg =
      getServerError(err.response?.data) || JSON.stringify(err.response || err);
    throw new Error(errorMsg);
  }
});

export const updateOrderStatus = createAsyncThunk(
  "orders/updateOrderStatus",
  async (payload) => {
    try {
      const response = await apiUpdateOrderStatus(payload);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

export const getDriverLocation = createAsyncThunk(
  "orders/getDriverLocation",
  async (id) => {
    try {
      const response = await apiGetDriverLocation(id);
      return response.data;
    } catch (err) {
      let errorMsg =
        getServerError(err.response?.data) ||
        JSON.stringify(err.response || err);
      throw new Error(errorMsg);
    }
  }
);

const initialState = {
  getAllOrdersApi: { data: [], loading: false, error: null },
  getOrderApi: { data: [], loading: false, error: null },
  updateOrderStatusApi: { data: [], loading: false, error: null },
  currentUserType: 0,
  currentOrderType: 0,
  getDriverLocationApi: { data: [], loading: false, error: null },
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    setCurrentUserType: (state, action) => {
      state.currentUserType = action.payload;
      localStorage.setItem("currentUserType", action.payload);
    },
    setCurrentOrderType: (state, action) => {
      state.currentOrderType = action.payload;
      localStorage.setItem("currentOrderType", action.payload);
    },
  },
  extraReducers: {
    [getAllOrders.fulfilled]: (state, action) => {
      state.getAllOrdersApi.loading = false;
      state.getAllOrdersApi.data = action.payload;
    },
    [getAllOrders.pending]: (state, action) => {
      state.getAllOrdersApi.loading = true;
    },
    [getAllOrders.rejected]: (state, action) => {
      state.getAllOrdersApi.loading = false;
      state.getAllOrdersApi.error = action.error.message;
    },
    [getOrder.fulfilled]: (state, action) => {
      state.getOrderApi.loading = false;
      state.getOrderApi.data = action.payload;
    },
    [getOrder.pending]: (state, action) => {
      state.getOrderApi.loading = true;
    },
    [getOrder.rejected]: (state, action) => {
      state.getOrderApi.loading = false;
      state.getOrderApi.error = action.error.message;
    },
    [updateOrderStatus.fulfilled]: (state, action) => {
      state.updateOrderStatusApi.loading = false;
      state.updateOrderStatusApi.data = action.payload;
    },
    [updateOrderStatus.pending]: (state, action) => {
      state.updateOrderStatusApi.loading = true;
    },
    [updateOrderStatus.rejected]: (state, action) => {
      state.updateOrderStatusApi.loading = false;
      state.updateOrderStatusApi.data = action.error.message;
    },
    [getDriverLocation.fulfilled]: (state, action) => {
      state.getDriverLocationApi.loading = false;
      state.getDriverLocationApi.data = action.payload;
    },
    [getDriverLocation.pending]: (state, action) => {
      state.getDriverLocationApi.loading = true;
    },
    [getDriverLocation.rejected]: (state, action) => {
      state.getDriverLocationApi.loading = false;
      state.getDriverLocationApi.error = action.error.message;
    },
  },
});

export const { setCurrentUserType, setCurrentOrderType } = ordersSlice.actions;
