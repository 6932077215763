import React, { useState } from "react";
import { Modal } from "@mui/material";
import CloseICon from "../../assets/images/close-icon.svg";
import Logout from "../../assets/images/logout.svg";
import Next from "../../assets/images/next.svg";
import Exit from "../../assets/images/exit.svg";
import { useDispatch } from "react-redux";
import { logOut, logoutSession } from "../../store/reducers/auth";
import { deleteToken } from "firebase/messaging";
import { messaging } from "../../firebase";
import { useTranslation } from "react-i18next";

const modalStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  position: "absolute",
  backgroundColor: "white",
  boxShadow: 24,
  padding: 16,
  boxSizing: "border-box",
};

const LogoutModal = () => {
  const [profileModal, setProfileModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logOutHandler = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("@user");
    localStorage.removeItem("@location");
    localStorage.removeItem("firebaseToken");
    localStorage.removeItem("showPermissionDeniedAlert");
    localStorage.removeItem("currentUserType");
    localStorage.removeItem("currentOrderType");
    dispatch(logOut());
    dispatch(logoutSession()).then((res) => {
      if(!res.error){
        deleteToken(messaging);
      }
    });
  };
  return (
    <>
      <li onClick={() => setProfileModal(true)}>
        <span>
          <img src={Logout} alt="" /> {t("logout")}
        </span>
        <span>
          <img src={Next} alt="" />
        </span>
      </li>

      <Modal
        open={profileModal}
        onClose={() => setProfileModal(false)}
        className="main-modal"
      >
        <div style={modalStyle} className="modal-content">
          <div className="modal-body">
            <div className="modal-header location">
              <span onClick={() => setProfileModal(false)}>
                <img src={CloseICon} alt="" />
              </span>
            </div>
            <div className="modal-body location">
              <div className="location-marker">
                <img src={Exit} alt="" />
                <h3>{t("logout_description")}</h3>
              </div>
              <div className="input-group button">
                <button
                  type="button"
                  variant="standard"
                  color="primary"
                  onClick={logOutHandler}
                >
                  {t("yes")}
                </button>
                <button
                  className="cancel-button"
                  onClick={() => setProfileModal(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
