import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../Layouts/PageLayouts";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { getAllNotification } from "../../store/reducers/notification";
import SpecificNotification from "./SpecificNotification";

const NotificationPage = () => {
  // const pageRef = useRef(1);

  const [paginationConfig, setPaginationConfig] = useState({
    params: {
      page_size: 15,
      page: 1,
      view_all: true,
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationToBeShown, setNotificationToBeShown] = useState([]);
  const [AllNotification, setAllNotification] = useState();
  const { t } = useTranslation();

  const { loading } = useSelector(
    (state) => state.notification?.getAllNotificationApi
  );

  useEffect(() => {
    setNotificationToBeShown((prevNotifications) => {
      return [...prevNotifications, ...(AllNotification?.results || [])];
    });
  }, [AllNotification?.results]);

  const fetchAllNotification = (paginationConfig) => {
    dispatch(getAllNotification(paginationConfig)).then((res) => {
      if (!res.error) {
        setAllNotification(res.payload);
      }
    });
  };

  useEffect(() => {
    fetchAllNotification(paginationConfig);
  }, [paginationConfig]);

  const loadNextPage = () => {
    const newPaginationConfig = { ...paginationConfig };
    paginationConfig.params.page = paginationConfig.params.page + 1;
    setPaginationConfig(newPaginationConfig);
  };

  return (
    <PageLayout
      showBackBtn={true}
      headerTitle={t("notification")}
      showNotification={false}
      showSearchField={false}
    >
      <Stack container mt={5} ml={4} mr={4}>
        {notificationToBeShown && (
          <InfiniteScroll
            dataLength={notificationToBeShown?.length}
            next={loadNextPage}
            hasMore={AllNotification?.next}
            loader={() => {
              loading && (
                <Typography variant="subtitle1" textAlign="center">
                  loading more ....
                </Typography>
              );
            }}
          >
            {notificationToBeShown?.length > 0 &&
              notificationToBeShown?.map((notification, index) => (
                <SpecificNotification key={index} notification={notification} />
              ))}
          </InfiniteScroll>
        )}
      </Stack>
      {loading && <CircularProgress sx={{ margin: "5rem 40rem" }}/>}
    </PageLayout>
  );
};

export default NotificationPage;
