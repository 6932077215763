import React from "react";
import PageLayout from "../../Layouts/PageLayouts";
import CloseICon from "../../assets/images/close-icon.svg";
import { Divider, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SubscriptionModal = ({ open, setOpen, data, handleModalClickHandler }) => {
  const modalStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    position: "absolute",
    backgroundColor: "white",
    boxShadow: 24,
    padding: 16,
    boxSizing: "border-box",
  };
  const {t} = useTranslation();

  return (
      <Modal open={open} onClose={setOpen} className="main-modal">
        <div style={modalStyle} className="modal-content">
          <div className="modal-body">
            <div className="modal-header action">
              <span onClick={() => setOpen(false)}>
                <img src={CloseICon} alt="" />
              </span>
            </div>
            <div className="modal-body subscription">
              <div className="action-reason">
                <h3>{t("payment")}</h3>
              </div>
              <div className="modal-body price">
                <div>{data?.lines?.data?.[1]?.description}</div>
                <div>${(data?.lines?.data?.[1]?.amount)/100}</div>
              </div>
              <div className="modal-body price">
                <div>{data?.lines?.data?.[0]?.description}</div>
                <div>${-1*(data?.lines?.data?.[0]?.amount)/100}</div>
              </div>
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                sx={{
                  color: "#434343",
                  margin: "1rem 0",
                  width: "100%",
                }}
              />
              <div className="modal-body price">
                <div>{t("amount_to_be_deducted")}</div>
                <div>${(data?.total)/100}</div>
              </div>
              <div className="modal-body upgrade-button">
                <button className="btn" onClick={() => {handleModalClickHandler(data?.lines?.data?.[1]?.plan?.id)}}>
                  <Typography
                    color="#FFFFFF"
                    fontWeight="600"
                    fontSize="16px"
                    textAlign="center"
                  >
                    {t("upgrade")}
                  </Typography>
                </button>
                <button className="cancel-btn" onClick={() => {setOpen(false)}}>
                  <Typography
                    color="#FFFFFF"
                    fontWeight="600"
                    fontSize="16px"
                    textAlign="center"
                  >
                    {t("cancel")}
                  </Typography>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
  );
};

export default SubscriptionModal;
