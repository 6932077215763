import { Container } from "@mui/system";
import Logo from "../../components/logo/logo";
import { CircularProgress, Typography } from "@mui/material";
import SubscriptionCard from "../../components/SubscriptionCard/SubscriptionCard";
import { Navigate, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageLayout from "../../Layouts/PageLayouts";
import { useSelector } from "react-redux";
import SubscriptionModal from "../../components/SubscriptionModal/SubscriptionModal";
import Loader from "../../components/Loader/Loader";
import CancelSubscriptionModal from "../../components/CancelSubscriptionModal/CancelSubscriptionModal";
import { useTranslation } from "react-i18next";
import { buySubscriptionPlans, cancelSubscriptionPlans, getSubscriptionPlans, userSubscriptionPlans, userTrialSubscriptionPlan } from "../../store/reducers/subscription";

const Subscription = () => {
  const dispatch = useDispatch();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [goldButton, setGoldButton] = useState("");
  const [silverButton, setSilverButton] = useState("");
  const [platinumButton, setPlatinumButton] = useState("");
  const [TrialButton, setTrialButton] = useState("");
  const [freeTrialShow, setFreeTrialShow] = useState(false);
  const { data: subscriptions, loading } = useSelector(
    (state) => state.subscription?.subscriptionPlansApi
  );

  const { loading: buySubscriptionsLoader } = useSelector(
    (state) => state.subscription?.buySubscriptionPlansApi
  );
  const { loading: cancelSubscriptionsLoader } = useSelector(
    (state) => state.subscription?.cancelSubscriptionPlansApi
  );
  const { loading: userFreeSubscriptionLoader } = useSelector(
    (state) => state.subscription?.userTrialSubscriptionPlanApi
  );
  const {
    data: userSubscriptionData,
    loading: userSubscriptionDetailLoader,
  } = useSelector((state) => state.subscription?.userSubscriptionPlansApi);

  const { user } = useSelector((state) => state.auth);
  const [upgradeApiLoader, setUpgradeApiLoader] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [dataForUpgrade, setDataForUpgrade] = useState({});
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const freeSubscriptionPlanData = {
    name: t("free"),
    price: "0.00",
    description: t("free_description"),
    is_subscribed: userSubscriptionData?.is_trial_active,
  };

  const fetchSubscriptionPlans = () => {
    dispatch(getSubscriptionPlans());
    setUpgradeApiLoader(false);
  };

  useEffect(() => {
    setSubscriptionPlans(subscriptions?.result);
  }, [subscriptions]);

  useEffect(() => {
    if (!user?.first_name) {
      navigate("/profile");
    }
    dispatch(userSubscriptionPlans());
    fetchSubscriptionPlans();
  }, []);

  useEffect(() => {
    if (subscriptionPlans?.length > 0) {
      if (subscriptionPlans[2]?.is_subscribed) {
        setSilverButton("");
        setGoldButton("");
        setPlatinumButton(t("cancel"));
        if (subscriptionPlans[2]?.is_cancelled) {
          setPlatinumButton(t("cancelled"));
        }
      } else if (subscriptionPlans[1]?.is_subscribed) {
        setSilverButton("");
        setGoldButton(t("cancel"));
        setPlatinumButton(t("upgrade"));
        if (subscriptionPlans[1]?.is_cancelled) {
          setGoldButton(t("cancelled"));
        }
      } else if (subscriptionPlans[0]?.is_subscribed) {
        setSilverButton(t("cancel"));
        setGoldButton(t("upgrade"));
        setPlatinumButton(t("upgrade"));
        if (subscriptionPlans[0]?.is_cancelled) {
          setSilverButton(t("cancelled"));
        }
      } else {
        setSilverButton(t("subscribe"));
        setGoldButton(t("subscribe"));
        setPlatinumButton(t("subscribe"));
      }
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    if (userSubscriptionData?.is_trial_accessed) {
      setTrialButton("");
    } else {
      setTrialButton(t("start"));
    }
    if (
      (userSubscriptionData?.is_trial_active &&
        userSubscriptionData?.is_trial_accessed) ||
      (!userSubscriptionData?.is_trial_active &&
        !userSubscriptionData?.is_trial_accessed)
    ) {
      setFreeTrialShow(true);
    } else {
      setFreeTrialShow(false);
    }
  }, [userSubscriptionData]);

  const fetchCancelSubscription = () => {
    dispatch(cancelSubscriptionPlans()).then((res) => {
      if (!res.error) {
        toast.success(t("subscription_cancelled_success"));
        fetchSubscriptionPlans();
      } else {
        console.log(res.error);
        toast.error(res.error?.message || t("something_went_wrong"));
      }
    });
    setCancelSubscriptionModal(false);
  };

  const fetchBuySubscription = (payload) => {
    dispatch(buySubscriptionPlans(payload)).then((res) => {
      if (!res.error) {
        if (res.payload?.checkout_url) {
          window.location.href = res.payload?.checkout_url;
        } else {
          setDataForUpgrade(res.payload);
          setUpgradeModal(true);
        }
      } else {
        console.log(res.error);
        toast.error(res.error?.message || t("something_went_wrong"));
      }
    });
  };

  const handleClick = async (data) => {
    if (data?.is_subscribed) {
      setCancelSubscriptionModal(true);
    } else {
      const payload = {
        price_tier: data?.price_id,
        is_upgrade: false,
      };
      fetchBuySubscription(payload);
    }
  };

  const handleUpgradePlan = (price_id) => {
    setUpgradeApiLoader(true);
    const payload = {
      price_tier: price_id,
      is_upgrade: true,
    };
    dispatch(buySubscriptionPlans(payload)).then((res) => {
      if (!res.error) {
        setTimeout(() => {
          fetchSubscriptionPlans();
          toast.success(t("subscription_upgraded_success"));
          navigate("/dashboard");
        }, 1000);
      } else {
        console.log(res.error);
        toast.error(res.error?.message || t("something_went_wrong"));
        setUpgradeApiLoader(false);
      }
    });
    setUpgradeModal(false);
  };

  const fetchFreeSubscriptionPlan = () => {
    dispatch(userTrialSubscriptionPlan()).then((res) => {
      if (!res.error) {
        toast.success(t("subscription_free_success"));
        dispatch(userSubscriptionPlans());
        navigate("/dashboard");
      } else {
        console.log(res.error);
        toast.error(res.error?.message || t("something_went_wrong"));
      }
    });
  };

  return (
    <PageLayout
      headerTitle={t("my_subscription")}
      showBackBtn={true}
      showSearchField={false}
      showNotification={false}
    >
      <Container>
        <div className="logo-container" style={{ marginTop: "50px" }}>
          <Logo />
        </div>
        <div className="subscription-container">
          <div className="heading">
            <Typography
              variant="h2"
              fontWeight="600"
              color="#2B2B2B"
              fontSize="28px"
              textAlign="center"
            >
              {t("our_subscription_plans")}
            </Typography>
          </div>
          <div className="subscription-cards">
            {!userSubscriptionDetailLoader && freeTrialShow && (
              <SubscriptionCard
                data={freeSubscriptionPlanData}
                handleClick={fetchFreeSubscriptionPlan}
                button={TrialButton}
              />
            )}
            <SubscriptionCard
              data={subscriptionPlans?.[0]}
              handleClick={handleClick}
              button={silverButton}
            />
            <SubscriptionCard
              data={subscriptionPlans?.[1]}
              handleClick={handleClick}
              button={goldButton}
            />
            <SubscriptionCard
              data={subscriptionPlans?.[2]}
              handleClick={handleClick}
              button={platinumButton}
            />
          </div>
        </div>
      </Container>
      <SubscriptionModal
        open={upgradeModal}
        setOpen={setUpgradeModal}
        data={dataForUpgrade}
        handleModalClickHandler={handleUpgradePlan}
      />
      <CancelSubscriptionModal
        open={cancelSubscriptionModal}
        setOpen={setCancelSubscriptionModal}
        clickHandler={fetchCancelSubscription}
      />
      {(loading ||
        upgradeApiLoader ||
        buySubscriptionsLoader ||
        cancelSubscriptionsLoader ||
        userFreeSubscriptionLoader ||
        userSubscriptionDetailLoader) && <Loader />}
    </PageLayout>
  );
};

export default Subscription;
