import React, { useState } from "react";
import { Alert, Container, Typography } from "@mui/material";
import { Formik } from "formik";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { Visibility, VisibilityOff } from "../../../components/inputFields";

import BackButton from "../../../assets/images/back.svg";
import * as Yup from "yup";
// import Logo from "../../components/logo/logo";

import Logo from "../../../components/logo/logo";
import { useDispatch, useSelector } from "react-redux";
// import { newPasswordRequest } from "../../store/reducers/auth";
import { useLocation, useNavigate } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiNewPasswordRequest } from "../../../apis/auth";
import { useTranslation } from "react-i18next";

export const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { apiNewPasswordApi } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const onSubmitHandler = (values) => {
    alert("From Reset Password");
    dispatch(
      apiNewPasswordRequest({
        ...values,
        ...location.state,
      })
    )
      // .then(unwrapResult)
      .then((res) => {
        toast.success(t("reset_password_success"));
        // setShowSnackbar(true)
        // setTimeout(() => {
        //   navigation("/login");
        // }, 1000);
      });
  };

  return (
    <Container maxWidth="md">
      <div className="logo-container">
        <Logo />
      </div>
      {/* <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Password has been reset with the new password.
        </Alert>
      </Snackbar> */}

      <div className="form-container">
        <div className="header-back-button min-bottom">
          <span className="backbutton" onClick={() => navigation(-1)}>
            <img src={BackButton} alt="" />
          </span>
          <Typography
            variant="h2"
            fontWeight="600"
            color="#2B2B2B"
            fontSize="28px"
            textAlign="center"
          >
            Reset Password
          </Typography>
        </div>
        <div className="input-group not-account">
          <span>Please enter a new password</span>
        </div>
        <div className="form">
          <Formik
            initialValues={{ new_password1: "", new_password2: "" }}
            validationSchema={Yup.object().shape({
              new_password1: Yup.string()
                .min(
                  8,
                  "Must be at least 8 characters, one letter, one number and one special character"
                )
                .matches(
                  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                  "Must be at least 8 characters, one letter, one number and one special character"
                )
                .required()
                .label("Password"),
              new_password2: Yup.string()
                .min(
                  8,
                  "Must be at least 8 characters, one letter, one number and one special character"
                )
                .matches(
                  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                  "Must be at least 8 characters, one letter, one number and one special character"
                )
                .required()
                .oneOf([Yup.ref("new_password1"), null], "Passwords must match")
                .label("Confirm password"),
            })}
            onSubmit={onSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <TextField
                    fullWidth
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    name="new_password1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.new_password1}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPassword(
                                (prevShowPassword) => !prevShowPassword
                              )
                            }
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span className="error-message">
                    {touched.new_password1 && errors.new_password1}
                  </span>
                </div>
                <div className="input-group">
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    type={showPassword1 ? "text" : "password"}
                    variant="standard"
                    name="new_password2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.new_password2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPassword1(
                                (prevShowPassword) => !prevShowPassword
                              )
                            }
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span className="error-message">
                    {touched.new_password2 && errors.new_password2}
                  </span>
                </div>
                <div className="input-group new-password-text">
                  <span>
                    Must be at least 8 characters, one letter, one number and
                    one special character
                  </span>
                </div>

                <div className="input-group button">
                  <Button variant="standard" color="primary" type="submit">
                    {apiNewPasswordApi.loading && (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#ffff", marginRight: "10px" }}
                      />
                    )}
                    Update
                  </Button>
                  <span className="error-message">
                    {!apiNewPasswordApi.loading && apiNewPasswordApi.error}
                  </span>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};
