
export const SPANISH_LANGUAGE = {
  dashboard_title: "Tablero",
  order_history_title: "Historial de Pedidos",
  check_in_time: "Hora de Registro",
  driver: "Conductor",
  plate_no: "No. de Placa",
  phone: "Teléfono",
  pick_up_no: "No. de Recogida",
  po_no: "No. de Orden de Compra",
  status: "Estado",
  gate_no: "No. de Muelle",
  action: "Acción",
  checked_in_order: "Pedidos Registrados",
  ongoing_order: "Pedidos en Curso",
  completed_order: "Pedidos Completados",
  not_check_in_order: "No tienes pedidos registrados",
  not_ongoing_order: "No tienes pedidos en curso",
  not_completed_order: "No tienes pedidos completados",
  no_order: "Sin Pedidos",
  at_moment: "en este momento",
  n_a: "N/D",
  view: "Ver",
  approve: "Aprobar",
  hold: "Esperar",
  confirm_driver_arrival: "Confirmar Llegada del Conductor",
  cancel: "Cancelar",
  mark_driver_arrived: "Marcar Llegada del Conductor",
  ready_for_loading: "Listo para Cargar",
  ready_off_loading: "Listo para Descargar",
  halt_loading: "Detener Carga",
  halt_off_loading: "Detener Descarga",
  mark_delivered: "Entrega Completa",
  mark_loading_complete: "Marcar Carga Completa",
  confirm_loading_complete: "Confirmar Carga Completa",
  confirm_delivery: "Confirmar Entrega",
  decline: "Rechazar",
  order_details_title: "Detalles del Pedido",
  pick_up_status: "Estado de Recogida",
  pick_up_details: "Detalles de Recogida",
  pick_up_number: "Número de Recogida",
  carrier_name: "Nombre del Transportista",
  trailer_plate_number: "Número de Placa del Remolque",
  driver_phone_number: "Número de Teléfono del Conductor",
  po_number: "Número de Orden de Compra",
  driver_location: "Ubicación del Conductor",
  driver_remarks: "Observaciones del Conductor",
  check_in_remark: "Observaciones de Registro",
  arrival_remark: "Observaciones de Llegada",
  loading_complete_remark: "Observaciones de Carga Completa",
  delivery_remark: "Observaciones de Entrega",
  checked_in_instruction: "Instrucciones de Registro",
  instruction: "Instrucciones",
  loading_instruction: "Instrucciones de Carga",
  loading_complete_instruction: "Instrucciones de Carga Completa",
  off_loading_instruction: "Instrucciones de Descarga",
  delivery_complete_instruction: "Instrucciones de Entrega Completa",
  reason_for_decline: "Motivo del Rechazo",
  reason_for_hold: "Motivo de Espera",
  reason_for_halt: "Motivo de Detención",
  reason: "Motivo",
  allowed_gate: "Muelle Permitido",
  add_new_instruction: "Agregar Nueva Instrucción",
  cancel_approval: "Presiona Enviar para Cancelar",
  driver_confirm_description: "Esta confirmación de la llegada del conductor también marcará el pedido como aprobado.",
  loading_complete_description: "Carga Completa: Instruir al conductor para firmar la Carta de Porte o proporcionar cualquier instrucción adicional",
  delivery_complete_description: "Entrega Completa: Instruir al conductor para recoger los documentos de entrega o proporcionar cualquier instrucción adicional",
  gate_placeholder: "Por favor, ingresa el número de muelle",
  comment_placeholder: "Comentario",
  submit: "Enviar",
  header_search_placeholder: "Buscar por Nombre, Placa, Transportista, Núm. de Recogida",
  notification: "Notificación",
  view_all_notification: "Ver Todas",
  user_profile: "Perfil de Usuario",
  distributor: "Distribuidor",
  receiver: "Receptor",
  checked_in_driver: " conductores registrados",
  ongoing_driver: " conductores en curso",
  completed_driver: " conductores completados",
  out_of: "de",
  of: "de",
  my_profile_title: "Mi Perfil",
  first_name: "Nombre",
  last_name: "Apellido",
  name: "Nombre",
  warehouse_name: "Nombre del Almacén",
  phone_number: "Número de Teléfono",
  email: "Correo Electrónico",
  location: "Ubicación",
  set_location: "Establecer Ubicación",
  hours_of_operation: "Horario de Operación",
  set_hours: "Establecer Horas",
  save: "Guardar",
  continue: "Continuar",
  account_setting: "Configuración de la Cuenta",
  terms_and_conditions: "Términos y Condiciones",
  privacy_policy: "Política de Privacidad",
  my_subscription: "Mi Suscripción",
  location_permission: "Permitir a Fast Trax acceder a tu ubicación mientras usas la aplicación",
  yes: "Sí",
  confirm_location: "Confirmar Ubicación",
  same_hours_every_day: "Mismo horario todos los días",
  free: "Gratis",
  free_description: "Plan de suscripción gratuita por 1 mes",
  cancelled: "Cancelado",
  upgrade: "Actualizar",
  subscribe: "Suscribirse",
  start: "Comenzar",
  our_subscription_plans: "Nuestros Planes de Suscripción",
  active: "Activo",
  payment: "Pago",
  amount_to_be_deducted: "Cantidad a deducir",
  cancel_subscription: "Cancelar Suscripción",
  cancel_subscription_description: "¿Estás seguro de que quieres cancelar tu suscripción?",
  no: "No",
  change_password: "Cambiar Contraseña",
  enter_new_password: "Por favor, ingresa una nueva contraseña",
  current_password: "Contraseña Actual",
  new_password: "Nueva Contraseña",
  confirm_password: "Confirmar Contraseña",
  change_password_validation: "Debe tener al menos 8 caracteres, una letra, un número y un carácter especial",
  password_match: "Las contraseñas deben coincidir",
  delete_account: "Eliminar Cuenta",
  delete_account_description: "¿Estás seguro de que quieres eliminar tu cuenta?",
  delete: "Eliminar",
  logout: "Cerrar Sesión",
  logout_description: "¿Estás seguro de que quieres cerrar sesión?",
  login: "Iniciar Sesión",
  password: "Contraseña",
  forgot_password: "¿Olvidaste tu Contraseña?",
  forgot_password_description: "Por favor, ingresa tu correo electrónico registrado para restablecer tu contraseña",
  dont_have_account: "¿No tienes una cuenta?",
  sign_up: "Registrarse",
  create_password: "Crear Contraseña",
  by_using_fast_trax: "Al usar Fast Trax, aceptas nuestros",
  and: "y",
  already_account: "¿Ya tienes una cuenta?",
  reset: "Restablecer",
  remember_password: "Recuerda tu contraseña",
  check_email: "Revisa tu correo electrónico",
  verify: "Verificar",
  not_received_otp: "¿No recibiste el OTP?",
  resend: "Reenviar",
  update: "Actualizar",
  password_success: "Contraseña cambiada exitosamente",
  plan_purchased_successfully: "Plan comprado exitosamente",
  logged_in_successfully: "Sesión iniciada exitosamente",
  reset_password_success: "La contraseña se ha restablecido con la nueva contraseña",
  registration_success: "Registro exitoso",
  profile_updated_success: "Perfil actualizado exitosamente",
  subscription_cancelled_success: "Plan de suscripción cancelado exitosamente",
  subscription_upgraded_success: "Plan de suscripción actualizado exitosamente",
  subscription_free_success: "Plan de suscripción gratuita activado exitosamente",
  something_went_wrong: "Algo salió mal",
  payment_failed: "Pago fallido",
  require_subscription: "Por favor, compra cualquier plan de suscripción para continuar",
  require_profile: "Por favor, configura tu perfil primero",
  send_feedback: "Enviar Comentarios",
  feedback: "Comentarios",
  feedback_sent: "Comentarios enviados exitosamente",
  feedback_placeholder: "Por favor, ingresa tus comentarios aquí",
  check_in_request: "Nueva Solicitud de Registro",
  driver_arrived: "Conductor Llegado",
  loading_complete: "Carga Completa",
  delivery_complete: "Entrega Completa",
  instruction_if_any: "Instrucción si la hay",
  read_all: "Leer Todo",
  block_notification_alert: "Por favor, habilita las notificaciones en la configuración de tu navegador para recibir notificaciones de nuestra parte. Puedes hacerlo haciendo clic en el icono a la izquierda de la barra de direcciones/búsqueda y cambiando los permisos.",
  location_placeholder: "Establecer tu ubicación permite a los conductores navegar por tu instalación",
  hours_placeholder: "Los horarios de tu operación serán vistos por los conductores en la aplicación",
  pending_status: "Pendiente",
  confirmed_status: "Confirmado",
  declined_status: "Rechazado",
  on_hold_status: "En Espera",
  driver_arrived_status: "Conductor Llegado",
  loading_paused_status: "Carga Pausada",
  loading_status: "Cargando",
  loading_complete_status: "Carga Completa",
  off_loading_paused_status: "Descarga Pausada",
  off_loading_status: "Descargando",
  delivered_status: "Entregado",
};
