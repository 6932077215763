import React, { useEffect, useState } from "react";
import { Modal, Switch, Checkbox } from "@mui/material";
import CloseICon from "../../assets/images/close-icon.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const label = { inputProps: { "aria-label": "Switch demo" } };

const modalStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  position: "absolute",
  backgroundColor: "white",
  boxShadow: 24,
  padding: 16,
  boxSizing: "border-box",
};

function appendTimeToDateTime(timeString) {
  var dateObject = new Date();
  var [hoursStr, minutesStr, amPm] = timeString.split(/:|(?=[APM])/);
  var hours = parseInt(hoursStr, 10);
  var minutes = parseInt(minutesStr, 10);
  if (amPm.toLowerCase() === "pm" && hours !== 12) {
    hours += 12;
  } else if (amPm.toLowerCase() === "am" && hours === 12) {
    hours = 0;
  }
  dateObject.setHours(hours);
  dateObject.setMinutes(minutes);
  dateObject.setSeconds(0);
  return dayjs(dateObject);
}

const HoursModal = ({
  open,
  onClose,
  setFieldValue,
  schedules,
  setProfile,
}) => {
  const [sameHours, setSameHours] = useState(false);
  const [schedulesData, setSchedules] = useState({
    Mon: {
      checked: true,
      opening_hour: dayjs(new Date().setHours(8, 0)),
      closing_hour: dayjs(new Date().setHours(17, 0)),
    },
    Tue: {
      checked: true,
      opening_hour: dayjs(new Date().setHours(8, 0)),
      closing_hour: dayjs(new Date().setHours(17, 0)),
    },
    Wed: {
      checked: true,
      opening_hour: dayjs(new Date().setHours(8, 0)),
      closing_hour: dayjs(new Date().setHours(17, 0)),
    },
    Thu: {
      checked: true,
      opening_hour: dayjs(new Date().setHours(8, 0)),
      closing_hour: dayjs(new Date().setHours(17, 0)),
    },
    Fri: {
      checked: true,
      opening_hour: dayjs(new Date().setHours(8, 0)),
      closing_hour: dayjs(new Date().setHours(17, 0)),
    },
    Sat: {
      checked: true,
      opening_hour: dayjs(new Date().setHours(8, 0)),
      closing_hour: dayjs(new Date().setHours(17, 0)),
    },
    Sun: {
      checked: true,
      opening_hour: dayjs(new Date().setHours(8, 0)),
      closing_hour: dayjs(new Date().setHours(17, 0)),
    },
  });
  const {t} = useTranslation();

  useEffect(() => {
    const data = {};
    const otherData = {};
    // debugger
    if (schedules.length > 0) {
      schedules.map((s) => {
        data[s.day_of_week] = {
          id: s.id,
          checked: true,
          opening_hour: appendTimeToDateTime(s.opening_hour),
          closing_hour: appendTimeToDateTime(s.closing_hour),
        };
      });
      Object.keys(schedulesData).map((d) => {
        otherData[d] = {
          ...schedulesData[d],
          checked: false,
        };
      });
      setSchedules({
        ...otherData,
        ...data,
      });
    }
  }, [schedules]);

  const handleOpeningHour = (name, value) => [
    setSameHours(false),
    setSchedules({
      ...schedulesData,
      [name]: {
        ...schedulesData[name],
        opening_hour: value,
      },
    }),
  ];

  const handleClosingHour = (name, value) => [
    setSameHours(false),
    setSchedules({
      ...schedulesData,
      [name]: {
        ...schedulesData[name],
        closing_hour: value,
      },
    }),
  ];

  const handleCheckbox = (name, value) =>
    setSchedules({
      ...schedulesData,
      [name]: {
        ...schedulesData[name],
        checked: value,
      },
    });

  const addSchedules = () => {
    const updatedSchedules = [];
    Object.keys(schedulesData).map((k) => {
      if (schedulesData[k].checked) {
        updatedSchedules.push({
          ...schedulesData[k],
          day_of_week: k,
          opening_hour: schedulesData[k].opening_hour,
          closing_hour: schedulesData[k].closing_hour,
        });
      }
    });
    setFieldValue("schedules", updatedSchedules);
    setProfile(updatedSchedules);
    onClose();
  };

  const onSameHoursHandler = (e) => {
    setSameHours(e.target.checked);
    if (e.target.checked) {
      const updatedSchedules = {};
      Object.keys(schedulesData).map((s) => {
        updatedSchedules[s] = {
          ...schedulesData[s],
          opening_hour: schedulesData["Mon"].opening_hour,
          closing_hour: schedulesData["Mon"].closing_hour,
        };
      });
      setSchedules(updatedSchedules);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className="main-modal">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={modalStyle} className="modal-content">
            <div className="modal-body">
              <div className="modal-header location">
                <span onClick={onClose}>
                  <img src={CloseICon} alt="" />
                </span>
              </div>
              <div className="modal-body location">
                <div className="location-marker">
                  <h3>{t("hours_of_operation")}:</h3>
                </div>
                <div className="switch-container">
                  <div className="switch-text">
                    <span>{t("same_hours_every_day")}</span>
                  </div>
                  <div className="switch-icon">
                    <Switch
                      {...label}
                      onChange={onSameHoursHandler}
                      checked={sameHours}
                    />
                  </div>
                </div>
                {Object.keys(schedulesData).map((s,index) => (
                  <div className="data-checkbox" key={index}>
                    <Checkbox
                      {...label}
                      checked={schedulesData[s].checked}
                      // color={"#7363F8"}
                      sx={{ color: "#7363F8" }}
                      onChange={(_, checked) => handleCheckbox(s, checked)}
                    />
                    <span
                      className="data-span"
                      style={{
                        backgroundColor: schedulesData[s].checked
                          ? "#7363F8"
                          : "#DADADA",
                      }}
                    >
                      {s}
                    </span>
                    <div className="time-picker-container">
                      <TimePicker
                        ampm={true}
                        onChange={(value) => handleOpeningHour(s, value)}
                        value={schedulesData[s].opening_hour}
                        className="time-picker"
                      />
                      <span>-</span>
                      <TimePicker
                        onChange={(value) => handleClosingHour(s, value)}
                        value={dayjs(schedulesData[s].closing_hour)}
                        className="time-picker"
                      />
                    </div>
                  </div>
                ))}

                <div className="input-group button hoursmodal">
                  <button
                    type="button"
                    variant="standard"
                    color="primary"
                    onClick={addSchedules}
                  >
                    {t("save")}
                  </button>
                  <button className="cancel-button" onClick={() => onClose()}>
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LocalizationProvider>
      </Modal>
    </>
  );
};

export default HoursModal;
