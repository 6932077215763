import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import MuiAppBar from "@mui/material/AppBar";

import SideMenu from "../../components/sidemenu/SideMenu";
import { Box, CircularProgress, Drawer, styled } from "@mui/material";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../firebase";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getOrder, updateOrderStatus } from "../../store/reducers/orders";
import { useTranslation } from "react-i18next";
import {
  firebaseToken,
  getAllNotification,
  readAllNotification,
} from "../../store/reducers/notification";
import NewCheckInReqModal from "../../components/NotificationModal/NewCheckInReqModal";
import { FIREBASE_VAPID_KEY } from "../../utils/constants/key";

const drawerWidth = 24;

const AppBar = styled(MuiAppBar)({
  width: `calc(100% - ${drawerWidth}rem)`,
  backgroundColor: "#fff",
  boxShadow: "none",
  // marginLeft: "24.4375rem",
  marginLeft: "24rem",
});

const PageLayout = ({
  children,
  headerTitle,
  showBackBtn,
  showSearchField = true,
  handleChange,
  fetchDashboardData = () => {},
  statusConfig,
  order_id,
  fetchOrderData,
  showNotification = true,
}) => {
  const { loading } = useSelector(state => state.orders.getOrderApi);

  const [data, setData] = useState();
  const [currentUserType, setCurrentUserType] = useState(0);

  const [openNewCheckInModal, setOpenNewCheckInModal] = useState(false);
  const onCloseNewCheckIn = () => setOpenNewCheckInModal(false);
  const dispatch = useDispatch();
  const [AllNotification, setAllNotification] = useState();
  const { t } = useTranslation();

  const { isNotificationFetched } = useSelector(state => state.notification);

  const fetchAllNotification = () => {
    dispatch(getAllNotification()).then(res => {
      if (!res.error) {
        setAllNotification(res.payload);
      }
    });
  };

  const createToken = async token => {
    localStorage.setItem("firebaseToken", token);
    dispatch(
      firebaseToken({
        registration_id: token,
        type: "web",
      }),
    );
  };

  const requestPermission = async ({ loadToken }) => {
    const permission = await Notification.requestPermission();
    if (
      permission === "denied" &&
      !localStorage.getItem("showPermissionDeniedAlert")
    ) {
      console.log("Permission denied");
      localStorage.setItem("showPermissionDeniedAlert", true);
      alert(t("block_notification_alert"));
    }
    if (permission === "granted") {
      console.log("Permission granted");
      const token = await getToken(messaging, {
        vapidKey: FIREBASE_VAPID_KEY,
      }).catch(err => {
        console.log("Error", err);
      });
      if (!token) {
        if (loadToken < 5) {
          requestPermission({ loadToken: loadToken + 1 });
          return;
        }
      }
      createToken(token);
    }
  };

  useEffect(() => {
    if (isNotificationFetched === false) {
      fetchAllNotification();
    }
    if (localStorage.getItem("firebaseToken") === null) {
      console.log("Requesting permission");
      requestPermission({ loadToken: 0 });
    }
  }, []);

  const fetchData = async id => {
    await dispatch(getOrder(id)).then(res => {
      if (!res.error) {
        setData(res?.payload);
      }
    });
  };

  // onMessage(messaging, payload => {
  //   console.log('[App] Message received.', payload);
  //   const jsonPayload = JSON.parse(payload?.data?.data);
  //   fetchData(jsonPayload?.order_id);
  //   fetchDashboardData(statusConfig);
  //   fetchAllNotification();
  //   if (jsonPayload?.order_id == order_id) {
  //     fetchOrderData(order_id);
  //   }
  //   setOpenNewCheckInModal(true);
  // });

  onMessage(messaging, payload => {
    console.log("[App] Message received.", payload);

    let jsonPayload = {};

    if (typeof payload?.data === "string") {
      try {
        jsonPayload = JSON.parse(payload.data);
      } catch (error) {
        console.error("Failed to parse payload.data:", error);
        return;
      }
    } else if (payload?.data && typeof payload.data === "object") {
      jsonPayload = payload.data;
    } else if (typeof payload?.data?.data === "string") {
      try {
        jsonPayload = JSON.parse(payload.data.data);
      } catch (error) {
        console.error("Failed to parse payload.data:", error);
        return;
      }
    } else if (payload?.data?.data && typeof payload.data.data === "object") {
      jsonPayload = payload.data.data;
    } else if (typeof payload === "string") {
      try {
        jsonPayload = JSON.parse(payload);
      } catch (error) {
        console.error("Failed to parse payload:", error);
        return;
      }
    } else if (typeof payload === "object") {
      jsonPayload = payload;
    } else {
      console.error("Invalid payload format:", payload);
      return;
    }

    fetchData(jsonPayload?.order_id);
    fetchDashboardData(statusConfig);
    fetchAllNotification();
    if (jsonPayload?.order_id === order_id) {
      fetchOrderData(order_id);
    }
    setOpenNewCheckInModal(true);
  });

  useEffect(() => {
    if (data?.receiver === null) {
      setCurrentUserType(0);
    } else {
      setCurrentUserType(1);
    }
  }, [data]);

  const clickHandler = popupPayload => {
    const payloadForPickup = {
      pickup_status: popupPayload.order_status,
      pickup_comment: popupPayload.order_comment,
      is_message: popupPayload?.is_message ? true : false,
    };
    const payloadForReciever = {
      delivery_status: popupPayload.order_status,
      delivery_comment: popupPayload.order_comment,
      is_message: popupPayload?.is_message ? true : false,
    };
    const payload = {
      id: popupPayload.id,
      payload:
        currentUserType === 0
          ? { ...payloadForPickup }
          : { ...payloadForReciever },
    };
    const updateStatus = async () => {
      await dispatch(updateOrderStatus(payload))
        .then(res => {
          if (!res?.error) {
            // toast.success("Status Changed successfully");
            console.log("Status Changed successfully");
          } else {
            toast.error(res?.error?.message || t("something_went_wrong"));
          }
        })
        .catch(err => {
          console.log(err);
        });
      setOpenNewCheckInModal(false);
      fetchDashboardData(statusConfig);
      if (popupPayload.id === order_id) {
        fetchOrderData(order_id);
      }
    };
    updateStatus();
    dispatch(readAllNotification()).then(res => {
      if (!res.error) {
        fetchAllNotification();
      }
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <AppBar position="fixed" open={true}>
          <Header
            headerTitle={headerTitle}
            showBackBtn={showBackBtn}
            showSearchField={showSearchField}
            handleChange={handleChange}
            AllNotification={AllNotification}
            showNotification={showNotification}
          />
        </AppBar>
        <Drawer
          sx={{
            width: `${drawerWidth}rem`,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: `${drawerWidth}rem`,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
          open={false}>
          <SideMenu />
        </Drawer>
        <Box
          component="main"
          sx={{
            marginTop: "3rem",
            // margin: "5rem auto",
            backgroundColor: "",
            flexGrow: 1,
          }}>
          {children}
        </Box>
      </Box>
      {loading ? (
        <CircularProgress sx={{ margin: "20rem 40rem" }} />
      ) : (
        <NewCheckInReqModal
          onClose={onCloseNewCheckIn}
          open={openNewCheckInModal}
          data={data}
          clickHandler={clickHandler}
          currentUserType={currentUserType}
        />
      )}
    </>
  );
};

export default PageLayout;
