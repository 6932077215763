import React, { useEffect, useState } from "react";
import PageLayout from "../../Layouts/PageLayouts";

import {
  Avatar,
  Button,
  Grid,
  Typography,
  styled,
  CircularProgress,
  Divider,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import { ReactComponent as ProfileImgPlaceholder } from "../../assets/images/profile-01.svg";
import { ReactComponent as PoNumberImg } from "../../assets/images/PoNumber.svg";
import { ReactComponent as PhoneIconImg } from "../../assets/images/PhoneIcon.svg";
import { ReactComponent as TrailerPlateIconImg } from "../../assets/images/PlateIcon.svg";

import { ReactComponent as TruckIconImg } from "../../assets/images/TruckImg.svg";

import { variables } from "../../assets/scss/variables";
import { OrderMap } from "./OrderMap";
import ActionButton from "../../components/DashboardActionButton";
import { useDispatch, useSelector } from "react-redux";
import { getOrder, updateOrderStatus } from "../../store/reducers/orders";
import ActionModal from "../../components/DashboardActionModal";
import { toast } from "react-toastify";
import { Instructions } from "./Instruction/Instructions";
import { orderStatusUtils } from "../../utils/constants/order";
import { useTranslation } from "react-i18next";
import DriverRemark from "./DriverRemarks/DriverRemark";

const PhoneIcon = styled(PhoneIconImg)({
  width: "1.375rem",
  hight: "1.375rem",
  "*": {
    // fill: "#FFFFFF !important",
  },
});

const PONumber = styled(PoNumberImg)({
  width: "1.375rem",
  hight: "1.375rem",
  "*": {
    // fill: "#FFFFFF !important",
  },
});

const TrailerPlateIcon = styled(TrailerPlateIconImg)({
  width: "1.375rem",
  hight: "1.375rem",
  "*": {
    // fill: "#FFFFFF !important",
  },
});

const TruckIcon = styled(TruckIconImg)({
  width: "1.375rem",
  hight: "1.375rem",
  "*": {
    // fill: "#FFFFFF !important",
  },
});

const CardTypography = styled(Typography)({
  fontFamily: "poppins",
  fontSize: "1rem",
  color: "#989CB8",
  fontWeight: "500",
  lineHeight: "18px",
});

const ProfilePlaceholder = styled(ProfileImgPlaceholder)({
  width: "3.625rem",
  hight: "3.625rem",
  "*": {
    // fill: "#FFFFFF !important",
  },
});

const ViewDetails = () => {
  const { state } = useLocation();
  const { data: order_id, currentUserType } = state;
  const [data, setData] = useState();
  const { loading } = useSelector(state => state.orders.getOrderApi);
  const dispatch = useDispatch();
  const [actionModal, setActionModal] = useState(false);
  const [payloadForActionModal, setPayloadForActionModal] = useState({});
  const [driverRemark, setDriverRemark] = useState();
  const { t } = useTranslation();

  const fetchData = async order_id => {
    if (!order_id) return;
    await dispatch(getOrder(order_id)).then(res => {
      if (!res.error) {
        setData(res.payload);
      }
    });
  };

  useEffect(() => {
    fetchData(order_id);
  }, []);

  useEffect(() => {
    if (currentUserType === 0) {
      setDriverRemark(data?.driver_pickup_remark);
    } else {
      setDriverRemark(data?.driver_delivery_remark);
    }
  }, [data, currentUserType]);

  const StyledButtonStatus = styled(Button)({
    borderRadius: "8px",
    padding: "6px 12px",
    marginLeft: "10px",
    disableElevation: true,
    textTransform: "capitalize",
  });

  const clickHandler = commentData => {
    const dataForPickup = {
      payload: {
        pickup_comment: commentData,
        pickup_status: payloadForActionModal.order_status,
        is_message: payloadForActionModal?.is_message ? true : false,
      },
    };
    const dataForDelivery = {
      payload: {
        delivery_comment: commentData,
        delivery_status: payloadForActionModal.order_status,
        is_message: payloadForActionModal?.is_message ? true : false,
      },
    };
    const payload = {
      id: payloadForActionModal.id,
      payload:
        currentUserType === 0
          ? { ...dataForPickup.payload }
          : { ...dataForDelivery.payload },
    };

    const updateStatus = async () => {
      await dispatch(updateOrderStatus(payload))
        .then(res => {
          if (!res?.error) {
            // toast.success("Status Changed successfully");
            console.log("Status Changed successfully");
          } else {
            toast.error(res?.error?.message || t("something_went_wrong"));
          }
        })
        .catch(err => {
          console.log(err);
        });
      setActionModal(false);
      fetchData(payload.id);
    };
    updateStatus();
  };

  return (
    <PageLayout
      headerTitle={t("order_details_title")}
      showBackBtn={true}
      showSearchField={false}
      order_id={order_id}
      fetchOrderData={fetchData}>
      {loading ? (
        <CircularProgress sx={{ margin: "20rem 40rem" }} />
      ) : (
        <Grid container p={6} flexDirection="column" sx={{ background: "" }}>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Typography mt={1}>{t("pick_up_status")}</Typography>
                  </Grid>
                  <Grid item>
                    <StyledButtonStatus
                      variant="outlined"
                      sx={{
                        color:
                          currentUserType === 0
                            ? `${orderStatusUtils.color[data?.pickup_status]}`
                            : `${
                                orderStatusUtils.color[data?.delivery_status]
                              }`,
                        borderColor:
                          currentUserType === 0
                            ? `${orderStatusUtils.color[data?.pickup_status]}`
                            : `${
                                orderStatusUtils.color[data?.delivery_status]
                              }`,
                      }}>
                      {currentUserType === 0
                        ? t(orderStatusUtils.label[data?.pickup_status])
                        : t(orderStatusUtils.label[data?.delivery_status])}
                    </StyledButtonStatus>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <ActionButton
                    row={data}
                    setActionModal={setActionModal}
                    setPayloadForActionModal={setPayloadForActionModal}
                    view={false}
                    currentUserType={currentUserType}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item pt={3} sx={{ background: "" }}>
            <Grid
              container
              justifyContent="space-between"
              sx={{ background: "" }}>
              {((data?.pickup_status !== "pending" &&
                data?.delivery_status !== "pending") ||
                driverRemark?.length > 0) && (
                <>
                  <Grid item xs={5.5}>
                    {driverRemark?.length > 0 && (
                      <DriverRemark driverRemark={driverRemark} />
                    )}
                    <Instructions
                      data={data}
                      setActionModal={setActionModal}
                      setPayloadForActionModal={setPayloadForActionModal}
                      currentUserType={currentUserType}
                    />
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation="vertical"
                      sx={{
                        background: "#222222",
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={5.5}>
                <Grid gap={3} container flexDirection="column">
                  <Grid item>
                    <Grid
                      container
                      gap={2}
                      p={3}
                      sx={{
                        background: "white",
                        border: "1px solid #C2C1BD91",
                        borderRadius: "1.5rem",
                      }}
                      flexDirection="column">
                      <Grid item>
                        <CardTypography>
                          {t("driver")} / {t("pick_up_details")}
                        </CardTypography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          flexDirection="row"
                          justifyContent="space-between">
                          <Grid
                            item
                            container
                            gap={2}
                            alignItems="center"
                            xs={8}>
                            <Grid item>
                              {data?.driver ? (
                                <Avatar
                                  src={data?.driver?.profile_picture}
                                  alt=""
                                />
                              ) : (
                                <ProfilePlaceholder />
                              )}
                            </Grid>
                            <Grid item>
                              <CardTypography
                                sx={{
                                  color: variables.primaryColor,
                                  textTransform: "capitalize",
                                }}>
                                {data?.driver?.first_name +
                                  " " +
                                  data?.driver?.last_name}
                              </CardTypography>
                            </Grid>
                          </Grid>
                          <Grid>
                            <a
                              href={data?.driver?.license_image}
                              target="_blank">
                              <Avatar
                                src={data?.driver?.license_image}
                                alt=""
                                sx={{
                                  width: "4rem",
                                  height: "4rem",
                                  borderRadius: "0.5rem",
                                }}
                              />
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <CardTypography>
                          {t("pick_up_number")}-
                          <span style={{ color: variables.primaryColor }}>
                            {data?.pickup_number}
                          </span>
                        </CardTypography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" gap={2}>
                          <Grid item>
                            <TruckIcon />
                          </Grid>
                          <Grid item>
                            <CardTypography>{t("carrier_name")}</CardTypography>
                            <CardTypography
                              sx={{
                                color: "#000000",
                                textTransform: "capitalize",
                              }}>
                              {data?.driver?.carrier_name}
                            </CardTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" gap={2}>
                          <Grid item>
                            <TrailerPlateIcon />
                          </Grid>
                          <Grid item>
                            <CardTypography>
                              {t("trailer_plate_number")}
                            </CardTypography>
                            <CardTypography sx={{ color: "#000000" }}>
                              {data?.driver?.license_plate_number}
                            </CardTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" gap={2}>
                          <Grid item>
                            <PhoneIcon />
                          </Grid>
                          <Grid item>
                            <CardTypography>
                              {" "}
                              {t("driver_phone_number")}{" "}
                            </CardTypography>
                            <CardTypography sx={{ color: "#000000" }}>
                              {data?.driver?.country_code +
                                "-" +
                                data?.driver?.phone_number}
                            </CardTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {currentUserType === 1 && (
                        <Grid item>
                          <Grid container alignItems="center" gap={2}>
                            <Grid item>
                              <PONumber />
                            </Grid>
                            <Grid item>
                              <CardTypography>{t("po_number")}</CardTypography>
                              <CardTypography sx={{ color: "#000000" }}>
                                {data?.po_number}
                              </CardTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation="horizontal"
                      variant="fullWidth"
                      sx={{
                        background: "#222222",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container gap={2} flexDirection="column">
                      <Grid item>
                        <CardTypography sx={{ color: "black" }}>
                          {t("driver_location")}
                        </CardTypography>
                      </Grid>
                      <Grid item>
                        <OrderMap
                          distributorDetails={
                            currentUserType === 0
                              ? data?.distributor
                              : data?.receiver
                          }
                          driverDetails={data?.driver}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <ActionModal
        open={actionModal}
        setOpen={setActionModal}
        title={payloadForActionModal.label}
        clickHandler={clickHandler}
        gateNo={payloadForActionModal.gate_no}
      />
    </PageLayout>
  );
};

export default ViewDetails;
