import React, { useState } from "react";
import PageLayout from "../../Layouts/PageLayouts";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { sendFeedback } from "../../store/reducers/auth";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Feedback = () => {
  const [feedback, setFeedback] = useState("");
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {loading} = useSelector((state) => state.auth.sendFeedbackApi);

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      feedback_text: feedback,
    }
    dispatch(sendFeedback(payload)).then((res) => {
      if(!res.error){
        setFeedback("");
        toast.success(t("feedback_sent"));
      }
      else{
        console.log("error", res.error);
        toast.error(res.error?.message || t("something_went_wrong"));
      }
    });
    // Here you can handle the feedback, for example, send it to a server
  };

  return (
    <PageLayout
      headerTitle="Feedback"
      showBackBtn={true}
      showSearchField={false}
    >
      <div className="feedback-container">
        <div className="heading">
          <Typography
            variant="h2"
            fontWeight="600"
            color="#2B2B2B"
            fontSize="28px"
            textAlign="center"
          >
            {t("feedback")}
          </Typography>
        </div>
        <form onSubmit={handleSubmit} className="feedback-form">
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="feedback-textarea"
            placeholder={t("feedback_placeholder") + " ..."}
          />
          <input
            type="submit"
            value={t("submit")}
            className="feedback-submit-button"
          />
        </form>
      </div>
      {loading && <Loader />}
    </PageLayout>
  );
};

export default Feedback;
