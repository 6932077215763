import React, { useState } from "react";
import { Alert, Container, Typography } from "@mui/material";
import { Formik } from "formik";
import { TextField, Button, CircularProgress } from "@mui/material";
import BackButton from "../../assets/images/back.svg";
import * as Yup from "yup";
import Logo from "../../components/logo/logo";
import { Link, useNavigate } from "react-router-dom";
import { resetPasswordRequest } from "../../store/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

export const ForgotPassword = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { api } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <div className="logo-container">
        <Logo />
      </div>
      <div className="form-container">
        <div className="header-back-button min-bottom">
          <span className="backbutton" onClick={() => navigation(-1)}>
            <img src={BackButton} alt="" />
          </span>
          <Typography
            variant="h2"
            fontWeight="600"
            color="#2B2B2B"
            fontSize="28px"
            textAlign="center"
          >
            {t("forgot_password")}
          </Typography>
        </div>
        <div className="input-group not-account">
          <span>
            {t("forgot_password_description")}!
          </span>
        </div>
        <div className="form">
          <Formik
            initialValues={{ email: "" }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required().label("Email"),
            })}
            onSubmit={(values) => {
              dispatch(
                resetPasswordRequest({
                  email: values.email,
                })
              )
                .then(unwrapResult)
                .then((res) => {
                  console.log(res);
                  navigation("/checkyouremail", {
                    state: {
                      email: values.email,
                    },
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <TextField
                    fullWidth
                    id="email"
                    label={t("email")}
                    variant="standard"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <span className="error-message">
                    {touched.email && errors.email}
                  </span>
                </div>
                <div
                  className="input-group button"
                  style={{ marginBottom: 20 }}
                >
                  <Button
                    variant="standard"
                    color="primary"
                    type="submit"
                    disabled={api.loading}
                  >
                    {api.loading && (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#ffff", marginRight: "10px" }}
                      />
                    )}
                    {t("reset")}
                  </Button>
                </div>
                <div className="input-group not-account">
                  <span>
                    {t("remember_password")}? <Link to="/login">{t("login")}</Link>
                  </span>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};
