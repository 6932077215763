import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./reducers/auth";
import { ordersSlice } from "./reducers/orders";
import { subscriptionSlice } from "./reducers/subscription";
import { notificationSlice } from "./reducers/notification";

const reducer = combineReducers({
  auth: authSlice.reducer,
  orders: ordersSlice.reducer,
  subscription: subscriptionSlice.reducer,
  notification: notificationSlice.reducer
});

const rootReducer = (rootState, action) => {
  return reducer(rootState, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
