import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toggleStatusForPagination } from "../../utils/constants/order";
import { useTranslation } from "react-i18next";

const Pagination = ({
  count,
  rowsPerPage,
  page,
  handlePageNavigation,
  currentToggle,
}) => {
  const [currentCount, setCurrentCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const {t} = useTranslation();

  useEffect(() => {
    setTotalPage(Math.max(1, Math.ceil(count / rowsPerPage)));
    setCurrentCount(Math.min(count, page * rowsPerPage));
  }, [count, page, rowsPerPage]);

  const handleChange = (name) => {
    if (page > 1 && name === "prev") {
      handlePageNavigation(name);
    } else if (page < totalPage && name === "next") {
      handlePageNavigation(name);
    }
  };

  return (
    <Grid
      p={2}
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ borderTop: "1px solid #D9D9D9" }}
    >
      <Grid item>
        <Typography>
          {currentCount} {t(toggleStatusForPagination[currentToggle])} {t("out_of")} {count}
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="space-around"
          sx={{
            width: "9rem",
            height: "2rem",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
            borderRadius: "2rem",
            backgroundColor: "",
          }}
        >
          <Grid
            item
            sx={{
              background: "",
              borderRight: "1px solid #D9D9D9",
              cursor: "pointer",
            }}
            onClick={() => handleChange("prev")}
          >
            <KeyboardArrowLeftIcon />
          </Grid>
          <Grid item>
            <Typography>
              {page} {t("of")} {totalPage}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ cursor: "pointer", borderLeft: "1px solid #D9D9D9" }}
            onClick={() => handleChange("next")}
          >
            <KeyboardArrowRightIcon />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Pagination;
