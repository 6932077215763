import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { FIREBASE_API_KEY } from "./utils/constants/key";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: "fast-trax-d59e9.firebaseapp.com",
  projectId: "fast-trax-d59e9",
  storageBucket: "fast-trax-d59e9.firebasestorage.app",
  messagingSenderId: "865718284162",
  appId: "1:865718284162:web:3e274261b10b1033c7a99b",
  measurementId: "G-S6Y8PB04WH",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
