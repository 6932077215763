import React from "react";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../StyledComponent/StyledToggleButton";
import { useTranslation } from "react-i18next";

const CustomToggleButton = ({
  setSelectedToggleButton,
  buttonNames,
  disabled,
  alignment,
  setAlignment,
}) => {
  // const [alignment, setAlignment] = React.useState(buttonNames[0].id);

  const { t } = useTranslation();

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      setSelectedToggleButton && setSelectedToggleButton(newAlignment);
    }
  };

  return (
    <div>
      <StyledToggleButtonGroup
        // disabled={disabled}
        fullWidth
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        sx={{
          height: "56px",
          width: buttonNames.length < 3 ? "21rem" : "30rem",
        }}
      >
        {buttonNames.map((button) => (
          <StyledToggleButton
            disableTouchRipple
            key={button.id}
            value={button.id}
            disabled={disabled}
            // aria-label={name}
          >
            {t(button.label)}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </div>
  );
};

export default CustomToggleButton;
