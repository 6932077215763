import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { toast } from "react-toastify";

const SuccessPayment = () => {
  const {t} = useTranslation();
  toast.success(t("plan_purchased_successfully"));
  return(
    <Navigate to="/dashboard" />
  )
};

export default SuccessPayment;
